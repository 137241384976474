import React from 'react'
import {useState} from 'react'
import {FaRegEye} from 'react-icons/fa'
import {FaRegEyeSlash} from 'react-icons/fa'

import styles from './FormInput.module.scss'

type FormInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  label: string
  value: string
  error?: null | string
  type?: string
}

function FormInput({onChange, name, label, value, error = null, type}: FormInputProps) {
  const [showPassword, setShowPassword] = useState(false)
  const inputType = type ? type : 'text'

  return (
    <div className={styles.base}>
      <input
        type={inputType === 'password' && showPassword ? 'text' : inputType}
        className={`${error !== null ? styles.error : ''}`}
        onChange={onChange}
        name={name}
        value={value}
      />
      {type === 'password' && !showPassword && (
        <i
          className={styles.userIcon}
          onClick={() => {
            setShowPassword(true)
          }}
        >
          <FaRegEye size='1em' />
        </i>
      )}
      {type === 'password' && showPassword && (
        <i
          className={styles.userIcon}
          onClick={() => {
            setShowPassword(false)
          }}
        >
          <FaRegEyeSlash size='1em' />
        </i>
      )}
      <label className={`${value ? styles.filled : ''}`}>{label}</label>
      {error && (
        <div className={styles.inputError} data-testid='input-error'>
          {error}
        </div>
      )}
    </div>
  )
}

export default FormInput
