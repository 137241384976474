const charsToIgnore = [
  '`',
  '~',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '-',
  '_',
  '=',
  '+',
  '{',
  '}',
  '[',
  ']',
  '\\',
  '|',
  ':',
  ';',
  "'",
  '"',
  ',',
  '.',
  '<',
  '>',
  '/',
  '?',
]

const highlightMarkUp = (markInstance, toMarkUpArr, highlightClassName) => {
  if (markInstance) {
    toMarkUpArr.forEach((text) => {
      const replacedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const reg = new RegExp('(?<!-)' + replacedText + '(?!-)', 'gmi')

      markInstance.markRegExp(reg, {
        className: highlightClassName,
        ignoreJoiners: true,
        acrossElements: true,
        ignorePunctuation: charsToIgnore,
      })
    })
  }
  return markInstance
}

export const highlightText = (markInstance, toMarkUpArr, highlightClassName) => {
  return highlightMarkUp(markInstance, toMarkUpArr, highlightClassName)
}
