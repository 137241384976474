import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as accountActions } from 'store/account'
import { FiMenu as Menu } from 'react-icons/fi'
import { ReactComponent as Logo } from 'assets/images/logo_color_nobg.svg'
import styles from './Header.module.scss'

function Header() {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const thisRef = useRef<HTMLDivElement>(null)
  const loggedIn = useSelector((state: RootState) => state.account.loggedIn)
  const roles = useSelector((state: RootState) => state.account.roles)
  const [menuOpened, setMenuOpened] = useState(false)

  const handleMenuIconClick = () => {
    setMenuOpened(!menuOpened)
  }

  //handle closing the menu when a click outside
  const handleClickOutside = (ev: Event) => {
    const eventTargetElement = ev.target as HTMLElement
    const eventTargetNode = eventTargetElement as Node

    if (eventTargetNode !== null && thisRef.current && !thisRef.current.contains(eventTargetNode)) {
      setMenuOpened(false)
    }
  }

  // set up listener to check for clicks outside dropdown
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const logOut = () => {
    dispatch(accountActions.logout())
    history(`/`)
  }

  return (
    <header className={`${styles.base} ${loggedIn ? '' : styles.centered}`} ref={thisRef}>
      {window.location.origin.includes('://insights') && (
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
      )}

      {loggedIn && (
        <>
          {/* <div className={styles.navigation}>
            {window.location.origin.includes('://insights') && <Link to='/report'>New Search</Link>}
            {window.location.origin.includes('://compare') && <Link to='/compare'>Compare</Link>}
          </div> */}
          <div className={styles.menu} data-testid="user-icon">
            <div className={styles.menuIcon} onClick={handleMenuIconClick}>
              <Menu size="2em" />
            </div>
            {menuOpened && (
              <div className={styles.dropdown} onClick={() => setMenuOpened(false)}>
                <Link to="/">
                  <div>Home</div>
                </Link>
                <Link to="/guidance">
                  <div>How to get good results</div>
                </Link>
                <Link to="/report">
                  <div>New search</div>
                </Link>
                <Link to="/settings/security">
                  <div className={styles.separator}>User settings</div>
                </Link>
                {roles?.includes('bo_admin') && (
                  <Link to="/settings/admin">
                    <div>Admin Area</div>
                  </Link>
                )}

                <div className={styles.separator} onClick={logOut}>
                  Log out
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </header>
  )
}

export default Header
