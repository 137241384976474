import { put, takeEvery, takeLatest, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../'
import { SearchRequest, SearchStatus, ReportStatus, ReportResponse } from './types'

//Each slice file should define a type for its initial state value, so that createSlice can correctly infer the type of
//state in each case reducer.
//All generated actions should be defined using the PayloadAction<T> type from Redux Toolkit, which takes the type
//of the action.payload field as its generic argument.

/**
 * Shape of the stores state
 */
export interface Search {
  ID: string
  name?: string
  full_text?: any
  wordPairs?: any
}

type PingResult = {
  status: number
  message?: string
}

type PingState = {
  success: boolean
  message?: string
}

export const SEARCH_TYPE_FIELD = {
  text: 'text',
  link: 'search_url',
  patentNumber: 'patent_number',
  limit: 'items_limit',
  file: 'file',
}

type loadingTypes = {
  searchStatus: boolean
  downloadReport: boolean
}

export type errorTypes = {
  searchStatus: string | null
}

export type ErrorKeyValueType = {
  key: keyof errorTypes
  value: null | string
}

type LoadingKeyValueType = {
  key: keyof loadingTypes
  value: boolean
}

export interface SearchState {
  searches: Array<Search>
  searchStatus: SearchStatus | null
  reportStatus: ReportStatus | null
  searchError: string | null
  generalError: string | null
  pingState: PingState | null
  loading: loadingTypes
  error: errorTypes
}

type SearchesResponse = AxiosResponse<Array<Search>>
type SearchResponse = AxiosResponse<Search> //TODO: it could be the same as SearchesResponse if we always return an array
type PingResponse = AxiosResponse<PingResult>

/**
 * Initial value(s) of the stores state
 */
const initialState: SearchState = {
  searches: [],
  searchStatus: null,
  reportStatus: null,
  searchError: null,
  generalError: null,
  pingState: null,
  loading: {
    searchStatus: false,
    downloadReport: false,
  },
  error: {
    searchStatus: null,
  },
}

/**
 * State selectors
 */
export const selectors = {
  selectSearch: (state: RootState, id: string) => state.search.searches.find(search => search.ID === id),
  selectSearchError: (state: RootState) => state.search.searchError,
  generalError: (state: RootState) => state.search.generalError,
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    fetchSearches: (state, action: PayloadAction<string | undefined>) => {},
    setSearches: (state, action: PayloadAction<Array<Search>>) => {
      state.searches = action.payload
    },
    addSearch: (state, action: PayloadAction<Search>) => {
      state.searches.push(action.payload)
    },
    addSearchError: (state, action: PayloadAction<string | null>) => {
      state.searchError = action.payload
    },
    clearSearchError: state => {
      state.searchError = null
    },
    updateSearch: (state, action: PayloadAction<Search>) => {
      const searchIndex: number = state.searches.findIndex(search => search.ID === action.payload.ID)
      state.searches[searchIndex] = action.payload
    },
    submitSearch: (state, action: PayloadAction<SearchRequest>) => {},
    getSearchStatus: (state, action: PayloadAction<string>) => {},
    setSearchStatus: (state, action: PayloadAction<SearchStatus | null>) => {
      state.searchStatus = action.payload
    },
    generateReport: (state, action: PayloadAction<string>) => {},
    setReportStatus: (state, action: PayloadAction<ReportStatus>) => {
      state.reportStatus = action.payload
    },
    downloadReport: (state, action: PayloadAction<Search>) => {},

    pingUrl: (state, action: PayloadAction<string>) => {},
    setPingState: (state, action: PayloadAction<PingState>) => {
      state.pingState = action.payload
    },
    clearPingState: state => {
      state.pingState = null
    },
    addGeneralError: (state, action: PayloadAction<string | null>) => {
      state.generalError = action.payload
    },
    clearGeneralError: state => {
      state.generalError = null
    },
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
    setError: (state, action: PayloadAction<ErrorKeyValueType>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
    clearStatus: state => {
      console.log('clearReportStatus')
      state.searchStatus = null
      state.reportStatus = null
      state.searches = []
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

const GENERAL_ERROR = 'Something went wrong, please try again. \nIf the problem continues, please contact us.'

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.fetchSearches, fetchSearchesSaga)
  yield takeEvery(actions.submitSearch, submitSearchSaga)
  yield takeLatest(actions.getSearchStatus, getSearchStatusSaga)
  yield takeLatest(actions.generateReport, generateReportSaga)
  yield takeEvery(actions.downloadReport, downloadReportSaga) // TODO delete deprecated
  yield takeLatest(actions.pingUrl, pingUrlSaga)
}

/**
 * Sagas
 */

const setLoadingTrue = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: true }))
}

const setLoadingFalse = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: false }))
}

export const fetchSearchesSaga = function* (action: any) {
  if (typeof action.payload === 'string') {
    try {
      const response: SearchResponse = yield call(Api.authGet, Api.ENDPOINTS.singleSearch(action.payload), {})

      yield put(actions.addSearch(response.data))
    } catch (error: any) {
      const errorMessage =
        error?.response?.status === 404
          ? "Can't find provided search ID. Please check if the URL is correct."
          : 'Something went wrong, please try again. \nIf the problem continues, please contact us.'
      yield put(actions.addGeneralError(errorMessage))
    }
  } else {
    const response: SearchesResponse = yield call(Api.authGet, Api.ENDPOINTS.searches, {})

    yield put(actions.setSearches(response.data))
  }
}

// const submitSearchSaga = function* (action: PayloadAction<{name: string} & {[key in SEARCH_TYPE_FIELD]?: string}>) {
export const submitSearchSaga = function* (action: PayloadAction<SearchRequest>) {
  const { name, ...fields } = action.payload

  const entries = Object.entries(fields)
  if (!entries.length) throw Error('Missing field defining search data')
  const [key, val] = entries[0]

  const typeKey = Object.values(SEARCH_TYPE_FIELD).find(value => value === key) || 'text'

  const data = {
    name,
    [typeKey]: val,
    filter: fields.filter,
    userWords: fields.userWords,
  }
  const body = fields.scrapeLimitIncreased === true ? { ...data, items_limit: 20 } : data

  let response: SearchResponse
  try {
    if (fields.file) {
      // when uploading file, send filter as json string if it exists
      if (body.filter) {
        body.filter = JSON.stringify(body.filter)
      }

      response = yield call(Api.authPostForm, Api.ENDPOINTS.searches, body)
      yield put(actions.addSearch(response.data))
    } else {
      response = yield call(Api.authPost, Api.ENDPOINTS.searches, body)
      yield put(actions.addSearch(response.data))
    }
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.exception ||
      error?.response?.data ||
      'There was an error processing search text. Try again or contact us if the issue continues.'
    yield put(actions.addSearchError(errorMessage))
  }
}

const getSearchStatusSaga = function* (action: PayloadAction<string>) {
  const searchId = action.payload
  yield setLoadingTrue('searchStatus')

  try {
    const response: AxiosResponse<SearchStatus> = yield call(Api.authGet, Api.ENDPOINTS.searchStatus(searchId), {})
    const status: SearchStatus = response.data
    yield put(actions.setSearchStatus(status))

    if (status === 'error') {
      yield put(actions.setError({ key: 'searchStatus', value: 'Something went wrong, please try again.' }))
      yield put(actions.addGeneralError('Something went wrong, please try again.'))
    }
    yield setLoadingFalse('searchStatus')
  } catch (error: any) {
    yield put(actions.setSearchStatus('error'))
    yield setLoadingFalse('searchStatus')
    const errorMessage =
      error?.response?.status === 404 ? "Can't find provided search ID. Please check if the URL is correct." : GENERAL_ERROR
    yield put(actions.setError({ key: 'searchStatus', value: errorMessage }))
  }
}

const generateReportSaga = function* (action: PayloadAction<string>) {
  const searchId = action.payload

  try {
    const response: AxiosResponse<ReportResponse> = yield call(
      Api.authPost,
      Api.ENDPOINTSTODELETE.generatePdfReport(searchId),
      {}
    )
    const status: ReportResponse = response.data
    if (status.search_status) yield put(actions.setSearchStatus(status.search_status))
    if (status.report_status) yield put(actions.setReportStatus(status.report_status))
    if (status.search_status === 'error' || status.report_status === 'error') {
      yield put(actions.addGeneralError('Something went wrong, please try again.'))
    }
  } catch (e) {
    yield put(actions.addGeneralError('Something went wrong, please try again.'))
  }
}

//// TODO delete deprecated
const downloadReportSaga = function* (action: PayloadAction<Search>) {
  try {
    yield setLoadingTrue('downloadReport')
    const search = action.payload
    const response: AxiosResponse<any> = yield call(Api.getBlob, Api.ENDPOINTSTODELETE.downloadReport(search.ID), {})

    let filename = 'report-' + search.name?.split('.').slice(0, -1).join('.') + '.pdf'
    yield setLoadingFalse('downloadReport')
    if (response?.headers && response?.headers['content-disposition']) {
      try {
        let headerLine = response.headers['content-disposition']
        let startFileNameIndex = headerLine.indexOf('"') + 1
        let endFileNameIndex = headerLine.lastIndexOf('"')
        filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
      } catch (error) {
        console.log('Error in try Catch for getting name from content-disposition header - ' + error)
      }
    }

    blobToPdf(response.data, filename)
  } catch (e) {
    yield setLoadingFalse('downloadReport')
    console.log(e)
  }
}

const pingUrlSaga = function* (action: PayloadAction<string>) {
  const incomingUrl = action.payload
  const url = incomingUrl.startsWith('http') ? incomingUrl : 'http://' + incomingUrl

  try {
    const response: PingResponse = yield call(Api.authPost, Api.ENDPOINTS.ping, {
      url,
    })
    const pageExists = response.data.status === 200
    const pageForbidden = response.data.status === 403
    const pingMessage = pageExists
      ? 'Ok'
      : pageForbidden
      ? "Forbidden - Our system can't access this address. Copy and paste the text and do a search text."
      : response.data.message
    yield put(actions.setPingState({ success: pageExists, message: pingMessage }))
  } catch (err: any) {
    yield put(
      actions.setPingState({
        success: false,
        message: 'Something went wrong. Please check if you are connected to the internet.',
      })
    )
  }
}

function blobToPdf(blob: any, reportName: any) {
  const file = new Blob([blob], { type: 'application/pdf' })
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = reportName
  link.click()
}
