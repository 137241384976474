import { Link } from 'react-router-dom'

import { BsFillCheckSquareFill as Check } from 'react-icons/bs'
import Title from 'components/title/Title'
import Button, { STYLE } from 'components/button/Button'
import styles from './GuidancePage.module.scss'

function GuidancePage() {
  return (
    <div className={styles.base}>
      <div className={styles.topSection}>
        <div className={styles.title}>
          <Title>Do you have your Patent Claims text?</Title>
        </div>
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.paragraph}>I don't have this.</div>
            <div className={styles.paragraph}>I'd rather search with Company Name.</div>
            <div className={styles.buttonContainer}>
              {/* <a target="_blank" href="" rel="noreferrer"> */}
              <Button
                title="Search with Company Name (Coming Soon)"
                style={STYLE.orange}
                type="button"
                onClick={() => {}}
                disabled={true}
                tooltip={"Coming soon"}
              />
              {/* </a> */}
            </div>
          </div>
        </div>

        <div className={`${styles.card} ${styles.greenCard}`}>
          <div className={styles.cardContent}>
            <div className={styles.paragraph}>
              <div className={styles.icon}>
                <Check />
              </div>
              <div>I have my patent Claims text.</div>
            </div>
            <div className={styles.paragraph}>
              <div className={styles.icon}>
                <Check />
              </div>
              <div>I know what to do.</div>
            </div>
            <div className={styles.buttonContainer}>
              <Link to="/report">
                <Button
                  title="Click to begin search"
                  style={STYLE.white}
                  type="button"
                  onClick={() => {}}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuidancePage
