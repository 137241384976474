import { useSelector } from 'react-redux'
import { RootState } from 'store'
import SearchRow from './components/searchRow/SearchRow'
import SearchColumn from './components/searchColumn/SearchColumn'
import styles from './SearchTable.module.scss'
import { Search } from 'store/adminArea/types'
import Spinner from 'components/spinner/Spinner'

type Props = {
  userId: string
  searches: Search[] | undefined
}
const SearchTable = ({ userId, searches }: Props) => {
  const error = useSelector((state: RootState) => state.adminArea.error.searches)
  const isLoading = useSelector((state: RootState) => state.adminArea.loading.searches)
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <SearchColumn customStyle="first">#</SearchColumn>
        <SearchColumn customStyle="id">id</SearchColumn>
        <SearchColumn customStyle="createdAt">Created at</SearchColumn>
        <SearchColumn customStyle="status">Search / Report status</SearchColumn>
        <SearchColumn customStyle="name">Name</SearchColumn>
        <SearchColumn customStyle="text">Text</SearchColumn>
        <SearchColumn customStyle="territories">Categories</SearchColumn>
        <SearchColumn customStyle="territories">Territories</SearchColumn>
        <SearchColumn customStyle="terms">Terms</SearchColumn>
        <SearchColumn customStyle="report">Report</SearchColumn>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {isLoading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {!isLoading && !error && (
        <>
          {searches?.length === 0 && <div className={styles.error}>No searches</div>}
          {searches?.map(search => (
            <SearchRow key={search.ID} userId={userId} search={search}></SearchRow>
          ))}
        </>
      )}
    </div>
  )
}

export default SearchTable
