import { useState, useEffect } from 'react'
import { RxDotFilled as Dot } from 'react-icons/rx'
import { Link, useLocation } from 'react-router-dom'
import { RxDoubleArrowLeft } from 'react-icons/rx'
import { RxDoubleArrowRight } from 'react-icons/rx'
import styles from './SidePanel.module.scss'

function SidePanel() {
  const location = useLocation()
  const [openPanel, setOpenPanel] = useState(false)
  const [showPanel, setShowPanel] = useState(true)

  useEffect(() => {
    if (location.pathname === '/report') setOpenPanel(true)
    if (location.pathname.includes('/settings')) setShowPanel(false)
  }, [location])


  const MainPanelContent = () => {
    return (
      <>
        <a target="_blank" href="https://www.elute.io/how-to-use-investor-insights" rel="noreferrer">
          <div className={styles.box}>
            <div className={styles.boxContent}>How to use Investor Insights</div>
          </div>
        </a>
        <a target="_blank" href="https://www.elute.io/sia" rel="noreferrer">
          <div className={styles.box}>
            <div className={styles.boxContent}>Understand the technology.</div>
          </div>
        </a>


        <a target="_blank" href="mailto:support@eluteintelligence.com" rel="noreferrer">
          <div className={styles.box}>
          <div className={styles.boxContent}>Ask us a question.</div>
          </div>
        </a>

        <a target="_blank" href="https://www.elute.io" rel="noreferrer">
          <div className={styles.box}>
            <div className={styles.boxContent}>Learn more about Elute.</div>
          </div>
        </a>
      </>
    )
  }

  const ReportPanelContent = () => {
    return (
      <>
        <div className={styles.box}>
          <div className={styles.boxContent}>
            <a target="_blank" href="https://www.elute.io/how-to-use-investor-insights" rel="noreferrer">
              How to use Investor Insights
            </a>

            <br />
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.boxContent}>
            Remove irrelevant results.
            <span className={styles.emphasized}>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <Dot />
                </div>
                <div> Filter by category</div>
              </div>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <Dot />
                </div>
                <div> Filter by country</div>
              </div>
            </span>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {showPanel && (
        <div className={`${styles.base} ${openPanel ? styles.opened : ''}`}>
          <div className={styles.sidepanel}>
            <div className={styles.panelBtnContainer}>
              <div
                className={`${styles.panelBtn} ${openPanel ? styles.opened : ''}`}
                onClick={() => {
                  setOpenPanel(!openPanel)
                }}>
                {openPanel ? (
                  <div>
                    <RxDoubleArrowRight size="1.5em" />
                  </div>
                ) : (
                  <div>
                    <RxDoubleArrowLeft size="1.5em" /> Learn
                  </div>
                )}
              </div>
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.content}>
                {location.pathname === '/report' ? <ReportPanelContent /> : <MainPanelContent />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SidePanel
