import { useState } from 'react'
import { Link } from 'react-router-dom'
import { postForm, ENDPOINTS } from 'services/api'
import { NullOrStringDictionary } from 'containers/user/types'
import ReCaptcha from 'components/reCaptcha/ReCaptcha'
import CardTitle from 'components/cardTitle/CardTitle'
import Button from 'components/button/Button'
import FormInput from 'components/formInput/FormInput'
import PasswordRequirements from 'components/passwordRequirements/PasswordRequirements'
import validatePassword from 'containers/user/utils'
import AccountCreated from './components/accountCreated/AccountCreated'
import styles from './SignUp.module.scss'
import Disclaimer from 'components/disclaimer/Disclaimer'

function SignUp(): JSX.Element {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  })
  const [signUpSubmitted, setSignUpSubmitted] = useState<boolean>(false)
  const [signUpDone, setSignUpDone] = useState(false)
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false)
  const [inputError, setInputError] = useState<NullOrStringDictionary>({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
  })
  const [signUpError, setSignUpError] = useState<null | string>(null)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true)

  const handleInputChange = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    setInputError({
      ...inputError,
      [event.target.name]: null,
    })
  }

  const formHasErrors = () => {
    const isValidPassword = validatePassword(data.password)
    const errorText = 'This field is required'
    setInputError({
      firstName: !data.firstName ? errorText : null,
      lastName: !data.lastName ? errorText : null,
      email: !data.email ? errorText : null,
      password: !data.password
        ? errorText
        : !isValidPassword
          ? 'Password must meet the following requirements'
          : null,
      errorCaptcha: !captchaVerified ? 'CAPTCHA response required' : null,
    })
    if (
      !data.firstName ||
      !data.lastName ||
      !data.email ||
      !data.password ||
      !isValidPassword ||
      !captchaVerified
    ) {
      return true
    }

    return false
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!formHasErrors()) {
      setSignUpSubmitted(true)
      submit()
    }
  }

  const submit = () => {
    const body = {
      ...data,
      acceptTermsOfUseAndPrivacyPolicy: 'yes',
    }
    try {
      const resp = postForm(ENDPOINTS.createUser, body)
      resp
        .then(r => {
          console.log(r)
          setSignUpDone(true)
        })
        .catch(error => {
          setSignUpSubmitted(false)
          setSignUpError('An error occurred. Please try again.')

          console.error(error)
        })
    } catch (error) {
      setSignUpError('An error occurred. Please try again.')
      console.log(error)
    }
  }

  return (
    <div className={styles.base}>

      <div className={styles.card}>
        {signUpDone && <AccountCreated email={data.email} />}
        {!signUpDone && (
          <>
            <div data-testid="signup-form">
              <CardTitle>Create an account.</CardTitle>
              <div className={styles.formContainer}>
                <form className={styles.form}>
                  <div className={styles.row}>
                    <div className={`${styles.col} ${styles.left}`}>
                      <FormInput
                        onChange={handleInputChange}
                        name="firstName"
                        label="First name"
                        value={data.firstName}
                        error={inputError.firstName}
                      />
                    </div>
                    <div className={`${styles.col} ${styles.right}`}>
                      <FormInput
                        onChange={handleInputChange}
                        name="lastName"
                        label="Last name"
                        value={data.lastName}
                        error={inputError.lastName}
                      />
                    </div>
                  </div>
                  <FormInput
                    onChange={handleInputChange}
                    name="email"
                    label="Email"
                    value={data.email}
                    error={inputError.email}
                  />
                  <FormInput
                    onChange={handleInputChange}
                    name="password"
                    label="Password"
                    value={data.password}
                    type="password"
                    error={inputError.password}
                  />
                  <PasswordRequirements password={data.password} />
                  <br></br>
                  {signUpError && (
                    <div className={styles.signUpError}>{signUpError}</div>
                  )}

                  <div className={styles.reCaptcha}>
                    <ReCaptcha
                      onChange={() => {
                        setCaptchaVerified(true)
                      }}
                    />
                    {inputError.errorCaptcha && (
                      <div className={styles.signUpError}>
                        CAPTCHA response required.
                      </div>
                    )}
                  </div>
                  <div className={styles.btnContainer}>
                    <Button
                      title="Sign up"
                      type="submit"
                      data-testid="submit"
                      disabled={signUpSubmitted}
                      loading={signUpSubmitted}
                      onClick={handleSubmit}
                    />
                  </div>
                  <div className={styles.footerText}>
                    <div>
                      By signing up, you agree to our{' '}
                      <a
                        target="_blank"
                        href="https://elute.io/terms-and-conditions"
                        rel="noreferrer">
                        Terms of service
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        href="https://elute.io/privacy-policy"
                        rel="noreferrer">
                        Privacy policy
                      </a>
                      .{' '}
                    </div>
                    <div className={styles.line}></div>
                    <div>
                      Already have an account? <Link to="/login">Log in</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
        <br />
      </div>
      {showDisclaimer && (
        <div className={styles.disclaimer}>
          <Disclaimer
            onClose={() => {
              setShowDisclaimer(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SignUp
