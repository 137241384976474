import styles from './Bullets.module.scss'

const Bullets = ({ score }: { score: number }) => {
  return (
    <div className={styles.base} data-testid="bullets">
      <div className={`${Number(score) < 15 ? styles.nomatch : ''}`}>
        <span
          className={`${styles.dot} ${
            Number(score) >= 15 ? styles.filled : ''
          } `}></span>
        <span
          className={`${styles.dot} ${
            Number(score) >= 40 ? styles.filled : ''
          } `}></span>
        <span
          className={`${styles.dot} ${
            Number(score) >= 65 ? styles.filled : ''
          } `}></span>
        <span
          className={`${styles.dot} ${
            Number(score) >= 90 ? styles.filled : ''
          } `}></span>
      </div>
    </div>
  )
}

export default Bullets
