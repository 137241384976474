import React, { useEffect, useCallback, useState, useRef } from 'react'
import Button from 'components/button/Button'
import Bullets from 'containers/compare/component/bullets/Bullets'
import Navigation from 'containers/compare/component/navigation/Navigation'
import styles from './ResultView.module.scss'
import { ResultViewTypeProps, TextResultType } from 'containers/compare/commonTypes'
import { highlightText } from 'containers/compare/containers/resultsStep/highlightText.js'
import Mark from 'mark.js'
let markInstance: any = null

const ResultView = ({ index, results, goBack }: ResultViewTypeProps) => {
  const handleBackBtnClick = (e: React.FormEvent) => goBack(true)
  const [selectedText, setSelectedText] = useState<null | TextResultType>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(index)
  const documentTextContainerRef = useRef<HTMLDivElement>(null)

  const highlightWithText = useCallback((toMarkUpArr: any[], highlightClassName: string) => {
    const node = documentTextContainerRef.current
    if (node !== null) {
      markInstance = new Mark(node)

      markInstance = highlightText(markInstance, toMarkUpArr, highlightClassName)

      const markupElements = node.getElementsByClassName(highlightClassName)

      if (markupElements?.length > 0) {
        const first = markupElements[0] as HTMLElement
        const parentOffsetTop = first.parentElement ? first.parentElement.offsetTop : 0
        node.scrollTop = first.offsetTop - parentOffsetTop - 10
      }
    }
  }, [])

  useEffect(() => {
    const highlightClassName = styles.selectedTextHighlight
    if (markInstance !== null) {
      markInstance.unmark({ className: highlightClassName })
    }

    if (results[currentIndex] && selectedText !== null) {
      const textsToHighlight = selectedText.sentencesResults?.map((res) => {
        return res.bestMatch.sentenceText
      }) || []

      highlightWithText(textsToHighlight, highlightClassName)
    }
  }, [selectedText])

  return (
    <div className={styles.base}>
      <div className={styles.title}>
        <Navigation
          title='Previous'
          left
          disabled={Boolean(currentIndex === 0)}
          onClick={() => setCurrentIndex(currentIndex - 1)}
        />
        <span className={styles.filename}>{results[currentIndex]?.filename}</span>
        <Navigation
          title='Next'
          right
          disabled={Boolean(currentIndex === results.length - 1)}
          onClick={() => setCurrentIndex(currentIndex + 1)}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.textsContainer}>
          <div className={styles.textsContainerTitle}>Match Sentences</div>

          {results[currentIndex].results?.map((res, index) => (
            <div
              className={`${styles.textBox} ${res === selectedText ? styles.activeTextBox : ''}`}
              onClick={() => setSelectedText(res)}
            >
              <div className={styles.textTitle}>
                Text {index + 1}
                <div>
                  <Bullets score={res.score} />
                </div>
              </div>

              <div>
                {res.sentencesResults?.map((sentences) => {
                  return <div>{sentences.searchSentenceText}</div>
                })}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.searchDocument} ref={documentTextContainerRef}>
          {results[currentIndex].documentText}
        </div>
      </div>

      <div className={styles.btnWrapper}>
        <Button title='Back' type='button' secondary onClick={handleBackBtnClick} />
      </div>
    </div>
  )
}

export default ResultView
