import CardTitle from 'components/cardTitle/CardTitle'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import {IoWarningOutline} from 'react-icons/io5'

import styles from './NeedsConfirmation.module.scss'

type Props = {
  sendConfirmationEmail: () => void
  confirmationEmailSent: boolean
}
function NeedsConfirmation({sendConfirmationEmail, confirmationEmailSent}: Props) {
  return (
    <div className={styles.card}>
      <CardTitle data-testid='loginForm'>Confirm your account.</CardTitle>
      <div className={styles.cardContent}>
        {confirmationEmailSent ? (
          <>
            <AiOutlineCheckCircle />
            <div>Confirmation link sent, please check your email to confirm it. </div>
          </>
        ) : (
          <>
            <div className={styles.iconWarning}>
              <IoWarningOutline />
            </div>
            You must validate your email. Check your inbox to confirm it or{' '}
            <div className={styles.link} onClick={sendConfirmationEmail}>
              click here to resend the confirmation email
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NeedsConfirmation
