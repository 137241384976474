import { createRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './ReCaptcha.module.scss'
const reCaptchaKey = `${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`

type Props = {
  onChange: () => void
}
function ReCaptcha({ onChange }: Props) {
  const reCaptchaRef = createRef<ReCAPTCHA>()
  // const onChange = (value: any) => {
  //   console.log('Captcha value:', value)
  // }
  return (
    <div className={styles.base}>
      <ReCAPTCHA ref={reCaptchaRef} sitekey={reCaptchaKey} onChange={onChange} />
    </div>
  )
}

export default ReCaptcha
