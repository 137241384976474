import { combineReducers } from 'redux'

import { reducer as search } from './search'
import { reducer as companySearch } from './companySearch'
import { reducer as dashboard } from './dashboard'
import { reducer as compare } from './compare'
import { reducer as account } from './account'
import { reducer as system } from './system'
import { reducer as adminArea } from './adminArea'
import { reducer as pinger } from './pinger'

const rootReducer = combineReducers({
  search,
  companySearch,
  dashboard,
  compare,
  account,
  system,
  adminArea,
  pinger,
})

export default rootReducer
