import styles from './BetaRibbon.module.scss'


function BetaRibbon() {

  return (
    <div className={styles.ribbonContainer}>
      <div className={styles.ribbonContainerInner}>
        <div className={styles.ribbon}>
          <h4>Beta</h4>
        </div>
      </div>
    </div>
  )
}

export default BetaRibbon