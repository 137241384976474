import styles from './RadioButton.module.scss'

type Props = {
  disabled?: boolean
  id: string
  searchType: string
  label: string
  selectedSearchType?: string
  setSelectedSearchType: (data: string) => void
  changeSearchOption: () => void
}

function RadioButton({
  id,
  disabled,
  searchType,
  label,
  selectedSearchType,
  setSelectedSearchType,
  changeSearchOption,
}: Props) {
  return (
    <div className={styles.base}>
      <input
        disabled={disabled}
        type='radio'
        id={id}
        name='searchType'
        value={searchType}
        defaultChecked={selectedSearchType === searchType}
        onClick={() => {
          setSelectedSearchType(searchType)
          changeSearchOption()
        }}
      />
      <label htmlFor={id} className={`${disabled ? styles.disabled : ''} `}>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
