import { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { hotjar } from 'react-hotjar'
import LogRocket from 'logrocket'

import { useAppDispatch } from 'store/hooks'
import { actions as accountActions } from 'store/account'
import { actions as pingerActions } from 'store/pinger'
import useInterval from 'use-interval'
import { isTokenExpired, refreshToken } from 'services/api'

import Header from 'components/header/Header'
import NotificationBanner from 'components/notificationBanner/NotificationBanner'
import SidePanel from 'components/sidePanel/SidePanel'
import BetaProgramme from 'containers/user/containers/betaProgramme/BetaProgramme'
import Login from 'containers/user/containers/login/Login'
import SignUp from 'containers/user/containers/signUp/SignUp'
import Confirmation from 'containers/user/containers/confirmation/Confirmation'
import AccountRecovery from 'containers/user/containers/accountRecovery/AccountRecovery'
import ResetPassword from 'containers/user/containers/resetPassword/ResetPassword'
import UpdatePassword from 'containers/user/containers/updatePassword/UpdatePassword'
import UserList from 'containers/adminArea/containers/userList/UserList'
import UserDetails from 'containers/adminArea/containers/userDetails/UserDetails'
import StartPage from 'containers/search/containers/startPage/StartPage'
import ReportRequest from 'containers/search/containers/textSearch/reportRequestPage/ReportRequestPage'
import ReportingPage from 'containers/dashboard/reportingPage/ReportingPage'
import DownloadReport from 'containers/search/containers/textSearch/downloadReportPage/DownloadReportPage'
import SearchWaitPage from 'containers/search/containers/textSearch/searchWaitPage/SearchWaitPage'
import GuidancePage from 'containers/search/containers/guidancePage/GuidancePage'
import Compare from 'containers/compare/Compare'
import BetaRibbon from 'components/betaRibbon/BetaRibbon'
import CompanySearch from 'containers/search/containers/companySearch/companySearchPage/CompanySearchPage'
import CompanySearchWaitPage from 'containers/search/containers/companySearch/companySearchWaitPage/CompanySearchWaitPage'
import PdfPrintReport from 'containers/dashboard/reportingPage/pdfPrintReport/PdfPrintReport'

import styles from './App.module.scss'

function App() {
  const dispatch = useAppDispatch()
  const loggedIn = useSelector((state: RootState) => state.account.loggedIn)
  const firstName = useSelector((state: RootState) => state.account.firstName)
  const lastName = useSelector((state: RootState) => state.account.lastName)
  const email = useSelector((state: RootState) => state.account.email)
  const releaseDate = useSelector((state: RootState) => state.pinger.releaseDate)
  const [hasUpdate, setHasUpdate] = useState(false)
  const releaseDateRef = useRef<string>()

  /* START HOTJAR */
  const hjid = Number(`${process.env.REACT_APP_HOTJAR_ID}`)
  const hjsv = Number(`${process.env.REACT_APP_HOTJAR_SV}`)
  hotjar.initialize(hjid, hjsv)
  hotjar.identify(email, { firstName: firstName, lastName: lastName })
  if (hotjar.initialized()) {
    hotjar.identify(email, { firstName: firstName, lastName: lastName })
  }
  /*END HOTJAR */

  if (!process.env.REACT_APP_DISABLE_LOGROCKET) {
    LogRocket.init('lsluej/insights-ui-dev', {
      dom: {
        inputSanitizer: false, //With this set to true, LogRocket will automatically obfuscate all user-input elements like <input> and <select>
      },
    })
    if (loggedIn) {
      LogRocket.identify(email, {
        name: firstName + ' ' + lastName,
        email: email,
      })
    }
  }

  // on refresh or app load check whether logged in
  useEffect(() => {
    refreshToken()
  }, [])

  useInterval(
    () => {
      if (isTokenExpired(localStorage.getItem('refreshToken'))) {
        dispatch(accountActions.logout())
      }
    },
    loggedIn ? 2000 : null
  ) // passing null will cancel the interval if it is already running

  useEffect(() => {
    if (releaseDate && !releaseDateRef.current) {
      releaseDateRef.current = releaseDate
    }
  })

  useEffect(() => {
    dispatch(pingerActions.getMaintenanceStatus())

    const interval = setInterval(() => {
      dispatch(pingerActions.getMaintenanceStatus())
    }, 60000)
    return () => clearInterval(interval)
  }, [dispatch])

  useEffect(() => {
    if (!hasUpdate && releaseDate && releaseDateRef.current !== releaseDate) {
      setHasUpdate(true)
    }
  }, [releaseDate, hasUpdate])

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/pdfprinter/report/:id" element={<PdfPrintReport />} />
        </Routes>

        {!window.location.pathname.startsWith('/pdfprinter') && (
          <div className={styles.base}>
            <BetaRibbon />
            {hasUpdate && <NotificationBanner />}
            <Header />
            {loggedIn && <SidePanel />}

            <Routes>
              <Route path="/index.html" element={<Navigate replace to="/" />} />
              <Route path="/private-beta-registration" element={loggedIn ? <Navigate replace to="/" /> : <SignUp />} />
              <Route path="/signup" element={loggedIn ? <Navigate replace to="/" /> : <BetaProgramme />} />
              <Route path="/login" element={loggedIn ? <Navigate replace to="/" /> : <Login />} />
              <Route path="/confirmation/:token" element={loggedIn ? <Navigate replace to="/" /> : <Confirmation />} />
              <Route path="/account-recovery" element={loggedIn ? <Navigate replace to="/" /> : <AccountRecovery />} />
              <Route path="/reset/:token" element={loggedIn ? <Navigate replace to="/" /> : <ResetPassword />} />

              {loggedIn && (
                <>
                  <Route path="/settings/security" element={<UpdatePassword />} />
                  <Route path="/settings/admin" element={<UserList />} />
                  <Route path="/settings/admin/user/:id" element={<UserDetails />} />

                  {window.location.origin.includes('://insights') && (
                    <>
                      <Route path="/" element={<StartPage />} />
                      <Route path="/guidance" element={<GuidancePage />} />
                      <Route path="/company/search" element={<CompanySearch />} />
                      <Route path="/dashboard/reporting/report/:id" element={<ReportingPage />} />
                      <Route path="/company/search/:id" element={<CompanySearchWaitPage />} />
                      <Route path="/report" element={<ReportRequest />} />
                      <Route path="/report/confirmation" element={<ReportRequest />} />
                      <Route path="/report/:id" element={<SearchWaitPage />} />
                      <Route path="/report/:id/download" element={<DownloadReport />} />
                    </>
                  )}

                  {window.location.origin.includes('://compare') && (
                    <>
                      <Route path="/" element={<Compare />} />
                      <Route path="/compare" element={<Compare />} />
                    </>
                  )}
                </>
              )}

              {!loggedIn && <Route path="/*" element={<Login />} />}
            </Routes>
          </div>
        )}
      </BrowserRouter>
    </div>
  )
}

export default App
