import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import store from './store'
import App from './containers/app/App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
