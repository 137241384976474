import { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { actions as accountActions } from 'store/account'
import { postForm, ENDPOINTS } from 'services/api'
import { CgSpinnerTwo as Spinner } from 'react-icons/cg'
import styles from './Confirmation.module.scss'

type paramsType = {
  token: string
}

function Confirmation(): JSX.Element {
  const { token } = useParams<paramsType>()
  const history = useNavigate()
  const dispatch = useAppDispatch()
  const [error, setError] = useState<boolean>(false)
  const [invalidToken, setInvalidToken] = useState<boolean>(false)

  useEffect(() => {
    const resp = postForm(ENDPOINTS.confirmUser, { token: token })
    resp
      .then(r => {
        dispatch(accountActions.setLoginWithToken(r.data))
        history(`/`)
      })
      .catch(error => {
        setError(true)
        console.log(error)
        if (error.response?.status === 401) {
          setInvalidToken(true)
        }
      })
  })

  return (
    <div className={styles.base}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          {error ? (
            <div className={styles.error}>
              {invalidToken ? (
                <>
                  <div>Confirmation token is invalid or has expired. </div>
                  <br />
                  Please,
                  <Link to="/private-beta-registration"> click here </Link>to
                  register again.
                </>
              ) : (
                <div>Something went wrong, please try again or contact us.</div>
              )}
            </div>
          ) : (
            <>
              <p>Your account is being confirmed.</p>
              <div className={styles.spinner}>
                <Spinner size="3em" />
              </div>
            </>
          )}
        </div>
      </div>
      <br />
    </div>
  )
}

export default Confirmation
