export const atLeastOneLowerCase = (password: string) => /(?=.*[a-z])/.test(password)
export const atLeastOneUpperCase = (password: string) => /(?=.*[A-Z])/.test(password)
export const atLeastOneDigit = (password: string) => /(?=.*\d)/.test(password)
export const atLeast8Characters = (password: string) => password.length >= 8

const validatePassword = (password: string) => {
  if (
    atLeastOneLowerCase(password) &&
    atLeastOneUpperCase(password) &&
    atLeastOneDigit(password) &&
    atLeast8Characters(password)
  )
    return true
  else return false
}

export default validatePassword
