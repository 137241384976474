import CardTitle from 'components/cardTitle/CardTitle'
import styles from './Disclaimer.module.scss'

type Props = {
  show?: boolean
  onClose: (event: any) => void
}

function Disclaimer({ show = true, onClose }: Props) {
  return (
    <>
      {show && (
        <div className={styles.base}>
          <div className={styles.title}>
            <CardTitle small>Beta disclaimer and brand name</CardTitle>
          </div>
          <div className={styles.cardContent}>
            The beta version of Investor Insights is in the process of being tested
            before its official release. The sole purpose is to conduct testing and
            obtain feedback. Elute Intelligence Limited provides no SLAs, no support,
            and no warranties, whether express or implied, as to the suitability or
            usability of the software or any of its content. Elute Intelligence
            Limited will not be liable for any loss, whether such loss is direct,
            indirect, special or consequential, suffered by any party as a result of
            their use of Investor Insights, its content and functionalities. Investor
            Insights is not intended for production use. The Investor Insights name
            is part of a temporary beta brand, and the live production version will
            be launched under a different name and brand.
            <div
              className={styles.close}
              onClick={() => {
                onClose(false)
              }}>
              Close
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Disclaimer
