import LogoWhiteImg from 'assets/images/logo_white_nobg.png'
import IconSquares from 'assets/images/icon_squares.png'
import styles from './Cover.module.scss'
import { SearchInfo } from 'store/dashboard/types'

type Props = {
  searchInfo: SearchInfo
}
const Cover = ({ searchInfo }: Props) => {
  return (
    <div className={styles.base}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>INVESTOR INSIGHTS</div>
        <img className={styles.img} alt={'img'} src={IconSquares} />
        <div className={styles.subTitle}>Competition Landscape v1 (Beta)</div>
      </div>
      <div className={styles.footer}>
        <img className={styles.logo} alt={'logo'} src={LogoWhiteImg} />
        <div className={styles.reportInfo}>
          <div>Report Name: {searchInfo.name}</div>
          <div>Date created: {searchInfo.created_at}</div>
          <div>
            User: {searchInfo.firstname} {searchInfo.lastname}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cover
