import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useInterval from 'use-interval'

import { useAppDispatch } from 'store/hooks'
import { actions as companyActions } from 'store/companySearch'
import { RootState } from 'store/'
import { ImSpinner9 as Spinner } from 'react-icons/im'

import styles from './CompanySearchWaitPage.module.scss'
import ReportDownloader from '../../reportDownloader/ReportDownloader'

function CompanySearchWaitPage() {
  const dispatch = useAppDispatch()

  const status = useSelector((state: RootState) => state.companySearch.searchResponse?.status)
  const logs = useSelector((state: RootState) => state.companySearch.searchResponse?.logs)
  const searchResponse = useSelector((state: RootState) => state.companySearch.searchResponse)

  const [start, setStart] = useState(true)

  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id && status !== 'search_finished') dispatch(companyActions.getStatus(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useInterval(
    () => {
      if (id && status !== 'search_finished') dispatch(companyActions.getStatus(id))
    },
    start ? 5000 : null
  ) // passing null instead of 1000 will cancel the interval if it is already running

  return (
    <div className={styles.base}>
      <div className={styles.first}>
        <div className={styles.content}>
          <div className={styles.title}>Grab a coffee, and we’ll get your report ready!</div>
        </div>
      </div>
      <div className={styles.second}>
        <div className={styles.progressCard}>
          Status:
          {status}
          {status === 'error' && <div className={styles.error}>{'There was an issue'}</div>}
          {status !== 'error' && status !== 'search_finished' && (
            <div>
              <Spinner className={styles.spinner} />
            </div>
          )}
          {status === 'search_finished' && (
            <div>
              {searchResponse?.search_ids.split('//').map(id => (
                <div>
                  <ReportDownloader id={id} />
                </div>
              ))}
            </div>
          )}
          <div className={styles.logs}>
            Company Name(s) ={' '}
            {searchResponse?.company_names.split('||||').map(c => (
              <div>{c}</div>
            ))}
          </div>
          <div className={styles.logs}>
            Search ID(s) ={' '}
            {searchResponse?.search_ids.split('//').map(s => (
              <div>{s}</div>
            ))}
          </div>
          <div className={styles.logs}>
            {searchResponse?.logs.map(l => (
              <div>{l}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySearchWaitPage
