import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import { authPatch, ENDPOINTS } from 'services/api'
import Spinner from 'components/spinner/Spinner'
import { FiExternalLink as ExternalLink } from 'react-icons/fi'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Title from 'components/title/Title'
import styles from './UserList.module.scss'

const UserList = () => {
  const dispatch = useAppDispatch()
  const users = useSelector((state: RootState) => state.adminArea.users)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    dispatch(dashboardActions.getUsers('insights'))
  }, [dispatch])

  useEffect(() => {
    if (users.length > 0) setIsLoading(false)
  }, [users])

  const updateUserDate = (id: string, key: string, date: Date) => {
    //TODO: update startDate doesn't work if endDate is not set.
    const userData = users?.find(user => user.id === id)
    const updatedUser = {
      ...userData,
      [key]: date.getTime(),
    }
    setIsLoading(true)

    const resp = authPatch(ENDPOINTS.updateUser(id), updatedUser)
    resp.then(r => {
      setIsLoading(false)
      refreshUser(id, key, date)
    })
    resp.catch(error => {
      setError(error?.message)
      setIsLoading(false)
    })
  }

  const refreshUser = (id: string, key: string, value: any) => {
    const newState = users?.map(user => {
      if (user.id === id) return { ...user, [key]: value }
      return user
    })
    dispatch(dashboardActions.setUsers(newState))
  }

  const getDate = (date: string) => {
    if (date) {
      return new Date(date)
    } else return null
  }

  type Props = {
    customStyle?: string
    children: JSX.Element | JSX.Element[] | string | string[]
  }
  const Column = ({ children, customStyle = '' }: Props) => {
    return <div className={`${styles.column} ${styles[customStyle]}`}>{children}</div>
  }

  return (
    <div className={styles.base}>
      <Title>Admin Area</Title>
      <div className={styles.tableContainer}>
        <div className={`${styles.row} ${styles.header}`}>
          <Column customStyle="user">User</Column>
          <Column>Role</Column>
          <Column customStyle="createdAt">Registration date</Column>
          <Column customStyle="date">Start date</Column>
          <Column>End date</Column>
          <Column>Status</Column>
          <Column>Searches last week</Column>
          <Column>Searches last month</Column>
        </div>
        <div>
          {isLoading && (
            <div className={styles.loading}>
              <Spinner />
            </div>
          )}
          {error && <div className={styles.error}>{error}</div>}
          {users?.map(user => {
            return (
              <div key={user.id} className={styles.row}>
                <Column customStyle="user">
                  <Link to={'/settings/admin/user/' + user.id}>
                    <span className={styles.name}>
                      {user.firstName} {user.lastName}
                      <ExternalLink size={'0.8em'} />
                    </span>
                    <br />
                    <span className={styles.email}>{user.email}</span>
                  </Link>
                </Column>

                <Column>{user.roles}</Column>
                <Column customStyle="createdAt">{user.createdAt?.slice(0, -2)}</Column>
                <Column customStyle="date">
                  <DatePicker
                    selected={getDate(user.startDate)}
                    onChange={(date: Date) => updateUserDate(user.id, 'startDate', date)}
                  />
                </Column>
                <Column customStyle="date">
                  <DatePicker
                    selected={getDate(user.endDate)}
                    onChange={(date: Date) => updateUserDate(user.id, 'endDate', date)}
                  />
                </Column>
                <Column customStyle="status">
                  <span className={`${styles[user.status]}`}>{user.status}</span>
                </Column>
                <Column>{user.searchesLastWeek}</Column>
                <Column>{user.searchesLastMonth}</Column>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default UserList
