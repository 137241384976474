import { useEffect } from 'react'
import { RootState } from 'store/'
import { useAppDispatch } from 'store/hooks'
import { useSelector } from 'react-redux'
import { ReportDataRequest, actions as dashboardActions } from 'store/dashboard'
import { useParams } from 'react-router'
import Cover from './cover/Cover'
import Report from '../report/Report'
import styles from './PdfPrintReport.module.scss'
import SearchOverview from './searchOverview/SearchOverview'
import LastPage from './lastPage/LastPage'
import Spinner from 'components/spinner/Spinner'

type ParamsType = {
  id: string
}

const PdfPrintReport = () => {
  const dispatch = useAppDispatch()
  const reportData = useSelector((state: RootState) => state.dashboard.reportData)
  const reportDataLoading = useSelector((state: RootState) => state.dashboard.loading.reportData)
  const params = useParams<ParamsType>()
  const searchId = params?.id || ''
  useEffect(() => {
    const data: ReportDataRequest = {
      id: searchId,
      isPdfPrinter: true,
    }
    dispatch(dashboardActions.fetchReportData(data))
  }, [dispatch, searchId])

  return (
    <div className={styles.base}>
      {reportDataLoading && (
        <div className={styles.loading}>
          <Spinner size={50} />
        </div>
      )}
      {reportData && (
        <>
          <Cover searchInfo={reportData.search_info} />
          <div className={styles.pageBreak}></div>
          <Report isPdfPrinter reportData={reportData} />
          <div className={styles.pageBreak}></div>
          <SearchOverview searchInfo={reportData.search_info} />
          <div className={styles.pageBreak}></div>
          <LastPage searchInfo={reportData.search_info} />
        </>
      )}
    </div>
  )
}

export default PdfPrintReport
