import { useCallback, useEffect, useState } from 'react'
import { actions as searchActions } from 'store/search'
import { useAppDispatch } from 'store/hooks'

import { FaInfoCircle as InfoCircle } from 'react-icons/fa'

import Title from 'components/title/Title'
import CardTitle from 'components/cardTitle/CardTitle'
import FormInput from 'components/formInput/FormInput'
import Button from 'components/button/Button'
import FileSearch from './components/fileSearch/FileSearch'
import CategoryPicker from './components/categoryPicker/CategoryPicker'
import RadioButton from './components/radioButton/RadioButton'
import { SEARCH_TYPE_FIELD } from 'store/search'
import { ReportRequestType, Category, Suggestions } from 'containers/search/types'
import styles from './SearchInputStep.module.scss'
import CountryPicker from './components/countryPicker/CountryPicker'
import WordSuggestor from './components/wordSuggestor/WordSuggestor'

type Props = {
  goToConfirmationStep: (value: boolean) => void
  setData: (data: undefined | ReportRequestType) => void
  setSearchFilter: (data: object) => void
  filter?: Object
  categoriesLevel1: Category[]
  categoriesLevel2: Category[]
  onCategorySelectOpen: Function
}

function SearchInputStep({
  goToConfirmationStep,
  setData,
  setSearchFilter,
  filter,
  categoriesLevel1,
  categoriesLevel2,
  onCategorySelectOpen = () => {},
}: Props) {
  const dispatch = useAppDispatch()

  const [companyName, setCompanyName] = useState<string>('')
  const [userWords, setUserWords] = useState<string | undefined>()
  const [text, setText] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [searchTextError, setSearchTextError] = useState<boolean>(false)
  const [selectedSearchType, setSelectedSearchType] = useState<string>(SEARCH_TYPE_FIELD.text)
  const [companyNameError, setCompanyNameError] = useState<boolean>(false)
  const [userWordsError, setUserWordsError] = useState<boolean>(false)
  const [ipcFilterError, setIpcFilterError] = useState<boolean>(false)
  const [countryilterError, setCountryFilterError] = useState<boolean>(false)

  useEffect(() => {
    dispatch(searchActions.clearPingState())
    dispatch(searchActions.clearSearchError())
  }, [dispatch])

  const updateFilter = useCallback(
    (param: string, value: (string | number)[]) => {
      setSearchFilter({ ...filter, [param]: value })
    },
    [filter, setSearchFilter]
  )

  const formIsValid = () => {
    let isValid = true
    if (!companyName) {
      isValid = false
      setCompanyNameError(true)
    }
    if (!filter?.['classificationsIPCR' as keyof typeof filter]?.length) {
      isValid = false
      setIpcFilterError(true)
    }

    if (!filter?.['countries' as keyof typeof filter]?.length) {
      isValid = false
      setCountryFilterError(true)
    }

    if (selectedSearchType === SEARCH_TYPE_FIELD.text) {
      var words = text.trim().split(/\s+/).length
      if (!text || words < 2) {
        isValid = false
        setSearchTextError(true)
      }
    } else if (selectedSearchType === SEARCH_TYPE_FIELD.file) {
      if (!file) {
        isValid = false
        setSearchTextError(true)
      }
    }
    return isValid
  }

  const handleSubmit = () => {
    if (formIsValid()) {
      if (selectedSearchType === SEARCH_TYPE_FIELD.text) {
        setData({
          type: SEARCH_TYPE_FIELD.text,
          value: text,
          name: companyName,
          filter,
          userWords: userWords,
        })
      } else if (selectedSearchType === SEARCH_TYPE_FIELD.file) {
        setData({
          type: SEARCH_TYPE_FIELD.file,
          value: file!,
          name: companyName,
          filter,
          userWords: userWords,
        })
      }
      goToConfirmationStep(true)
    }
  }

  const changeSearchOption = () => {
    setData(undefined)
    setSearchTextError(false)
  }

  const TextGuideLink = () => {
    return (
      <a className={styles.textGuide} target="_blank" href="https://www.elute.io/where-are-patent-claims" rel="noreferrer">
        <InfoCircle size={'0.9em'} />
        What is a patent claim?
      </a>
    )
  }

  const flatternList = (list: (string | number)[]) => {
    let flattened = list
      .map(l => {
        if (typeof l === 'string') {
          return l.split(',')
        } else return l
      })
      .flat()

    return flattened
  }

  return (
    <div className={styles.base}>
      <Title>Please fill out the following fields.</Title>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <CardTitle>Search Input Information</CardTitle>
          <div className={styles.cardContent}>
            <div className={styles.block}>
              <div className={styles.blockOne}>
                <div className={styles.row}>
                  <div className={styles.formInputContainer}>
                    <FormInput
                      onChange={event => {
                        setCompanyNameError(false)
                        setCompanyName(event?.target.value)
                      }}
                      name="companyName"
                      label="Company/Project Name"
                      value={companyName}
                    />

                    {companyNameError && <div className={styles.error}>Required</div>}
                  </div>
                </div>
                {/* <div className={styles.row}>
                  <div className={styles.inputContainer}>
                    <WordSuggestor
                      setUserWords={(words: string) => {
                        setUserWords(words)
                      }}
                      onSelectChange={(data: Suggestions) => {
                        setUserWordsError(false)
                        // if (data.categories) {
                        //   const cat: string[] = Array.from(data.categories.keys())
                        //   updateFilter('classificationsIPCR', cat)
                        // } else updateFilter('classificationsIPCR', [])
                      }}
                    />
                  </div>
                  {userWordsError && <div className={styles.error}>Required</div>}
                </div> */}

                <div className={styles.row}>
                  <div className={styles.inputContainer}>
                    <CategoryPicker
                      unfilteredCategoriesLevel1={categoriesLevel1}
                      unfilteredCategoriesLevel2={categoriesLevel2}
                      onSelectChange={(val: (string | number)[]) => {
                        setIpcFilterError(false)
                        updateFilter('classificationsIPCR', flatternList(val))
                      }}
                      onOpen={onCategorySelectOpen}
                    />

                    {ipcFilterError && <div className={styles.error}>Required hola</div>}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.inputContainer}>
                    <CountryPicker
                      onSelectChange={(val: (string | number)[]) => {
                        setCountryFilterError(false)
                        updateFilter('countries', val)
                      }}
                    />

                    {countryilterError && <div className={styles.error}>Required</div>}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.block}>
              <div className={styles.blockTwo}>
                <div className={styles.searchOptions}>
                  <RadioButton
                    id="textSearch"
                    searchType={SEARCH_TYPE_FIELD.text}
                    label="Enter patent Claims text"
                    selectedSearchType={selectedSearchType}
                    setSelectedSearchType={setSelectedSearchType}
                    changeSearchOption={changeSearchOption}
                  />

                  <div className={styles.connector}></div>

                  <RadioButton
                    disabled
                    id="fileSearch"
                    searchType={SEARCH_TYPE_FIELD.file}
                    label="Upload a document"
                    selectedSearchType={selectedSearchType}
                    setSelectedSearchType={setSelectedSearchType}
                    changeSearchOption={changeSearchOption}
                  />
                </div>

                {selectedSearchType === SEARCH_TYPE_FIELD.text && (
                  <div className={`${styles.searchTextContent} ${searchTextError ? styles.searchTextError : ''}`}>
                    <textarea
                      onChange={e => setText(e.target.value)}
                      placeholder={'Paste patent Claims text here'}
                      value={text}
                    />

                    <TextGuideLink />
                  </div>
                )}

                {selectedSearchType === SEARCH_TYPE_FIELD.file && (
                  <div className={`${styles.searchTextContent} ${searchTextError ? styles.searchTextError : ''}`}>
                    <FileSearch setFile={data => setFile(data)} file={file} />

                    <TextGuideLink />
                  </div>
                )}

                <div className={styles.buttonContainer}>
                  <Button
                    title="Create your report"
                    subtitle="(min. 200 words, please.)"
                    type="button"
                    big
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchInputStep
