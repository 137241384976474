import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { IoCaretBackOutline as BackArrow } from 'react-icons/io5'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions } from 'store/adminArea'
import SearchTable from './components/searchTable/SearchTable'
import styles from './UserDetails.module.scss'

const UserDetails = () => {
  const { id } = useParams<string>()
  const dispatch = useAppDispatch()
  const userDetails = useSelector((state: RootState) => state.adminArea.userDetails)
  const user = userDetails.id === id ? userDetails.user : null
  const searches = userDetails.id === id ? userDetails.searches : undefined

  useEffect(() => {
    if (id) {
      dispatch(dashboardActions.getUser(id))
      dispatch(dashboardActions.getUserSearches(id))
    }
  }, [dispatch, id])

  return (
    <>
      {id && (
        <div className={styles.base}>
          <span className={styles.goback}>
            <Link to="/settings/admin">
              <BackArrow size="1.1em" />
              Back
            </Link>
          </span>
          <div className={styles.title}>
            Searches for {user?.firstName} {user?.lastName}
          </div>
          <SearchTable userId={id} searches={searches} />
        </div>
      )}
    </>
  )
}

export default UserDetails
