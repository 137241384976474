import styles from './NotificationBanner.module.scss'

const NotificationBanner = () => {
  return (
    <div className={styles.base}>
      <p>A new update is available. Please refresh the page.</p>
    </div>
  )
}

export default NotificationBanner
