import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import Switch from 'react-switch'
import CardTitle from 'components/cardTitle/CardTitle'
import Bullets from 'containers/compare/component/bullets/Bullets'
import Button from 'components/button/Button'
import ResultView from './containers/resultView/ResultView'
import { CompareResultType } from 'containers/compare/commonTypes'
import styles from './ResultsStep.module.scss'
import Spinner from 'components/spinner/Spinner'

type ResultStepTypeProps = {
  newSearch: () => void
}

function ResultsStep({ newSearch }: ResultStepTypeProps) {
  const compareResults = useSelector((state: RootState) => state.compare.results)
  const fetchResultsStatus = useSelector((state: RootState) => state.compare.fetchResultsStatus)
  const [resultToView, setResultToView] = useState<number | any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [orderedResults, setOrderedResults] = useState<CompareResultType[]>([])
  const [debugMode, setDebugMode] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    if (fetchResultsStatus === 'loading') {
      setIsLoading(true)
    } else if (fetchResultsStatus === 'error') {
      setIsLoading(false)
      setIsError(true)
    } else {
      setIsLoading(false)
    }
  }, [fetchResultsStatus])

  useEffect(() => {
    if (compareResults) {
      const data = compareResults.slice().sort((a, b) => {
        const totalScoreA = a.results!.reduce((accumulator, current) => accumulator + current.score, 0)
        const totalScoreB = b.results!.reduce((accumulator, current) => accumulator + current.score, 0)
        return totalScoreA < totalScoreB ? 1 : -1
      })
      setOrderedResults(data)
    }
  }, [compareResults])

  const handleDebugMode = () => setDebugMode(!debugMode)

  return (
    <div className={styles.base}>
      <div className={styles.newSearchBtn}>
        <Button title="New Search" type="button" onClick={() => newSearch()} />
      </div>
      <CardTitle>Results</CardTitle>
      {resultToView === null && (
        <div className={styles.tableContainer}>
          <div className={`${styles.row} ${styles.header}`}>
            <div className={styles.number}>#</div>
            <div className={styles.nameCell}>file name</div>
            {compareResults.length > 0 && (
              <>
                {compareResults[0].results?.map((textResult, index) => {
                  return <div>text {index + 1}</div>
                })}
              </>
            )}
            <div></div>
          </div>
          {isLoading && <Spinner />}
          {isError && <div data-testid="error">Error</div>}
          {orderedResults.map((result, idx) => {
            return (
              <div className={styles.row}>
                <div className={styles.number}>{idx + 1} </div>
                <div className={styles.nameCell}>{result.filename}</div>

                {result.results?.map(textResult => {
                  return (
                    <div>
                      <Bullets score={textResult.score} />
                    </div>
                  )
                })}

                <div className={styles.viewBtn}>
                  <Button title="View" type="button" onClick={() => setResultToView(idx)} />
                </div>
              </div>
            )
          })}
        </div>
      )}

      {resultToView !== null && (
        <ResultView results={orderedResults} index={resultToView} goBack={() => setResultToView(null)} />
      )}
      {/* Temporary */}
      <div className={styles.debugSwitchBtn}>
        <Switch
          onChange={handleDebugMode}
          checked={debugMode}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={16}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="1px 1px 1px 4px rgba(0, 0, 0, 0.2)"
          height={14}
          width={28}
        />
      </div>
      {debugMode && (
        <div>
          {compareResults.map((search, idx) => {
            return (
              <div className={styles.debugContainer}>
                <br />
                <br />
                =======================================================================================
                <div>FILENAME: {search.filename} </div>
                <div>
                  {search.results?.map((textResult, idx) => {
                    return (
                      <div>
                        <br />
                        <br />
                        <div>
                          ******** Score for text {idx} = {textResult.score} % ********
                        </div>
                        {textResult.sentencesResults?.map((sentenceResult, idx2) => {
                          return (
                            <div>
                              <div>
                                <br />
                                {'>>>>>>>>>>>>>>>>>>>>'}
                              </div>
                              <div>
                                Sentence {idx2 + 1} has score of{' '}
                                {sentenceResult.bestMatch?.intersectionContentPercent}{' '}
                              </div>
                              <div>{sentenceResult.searchSentenceText}</div>
                              <div>-------</div>
                              <div>{sentenceResult.bestMatch?.sentenceText}</div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )}
      {/* END Temporary */}
    </div>
  )
}

export default ResultsStep
