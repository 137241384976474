import { useState } from 'react'
import { authPostForm, ENDPOINTS } from 'services/api'
import FormInput from 'components/formInput/FormInput'
import Button from 'components/button/Button'
import CardTitle from 'components/cardTitle/CardTitle'
import PasswordRequirements from 'components/passwordRequirements/PasswordRequirements'
import validatePassword from 'containers/user/utils'
import styles from './UpdatePassword.module.scss'

function UpdatePassword(): JSX.Element {
  const user = JSON.parse(localStorage.getItem('user')!)
  const [data, setData] = useState({
    currentPassword: '',
    newPassword: '',
  })

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [inputError, setInputError] = useState<null | string>(null)
  const [error, setError] = useState('')
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const handleInputChange = (event: any) => {
    setPasswordUpdated(false)
    setFormSubmitted(false)
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    setError('')
    setInputError(null)
  }

  const resetInputFields = () => {
    setData({
      currentPassword: '',
      newPassword: '',
    })
  }

  const handleSubmit = () => {
    setFormSubmitted(true)

    const isValidPassword = validatePassword(data.newPassword)
    if (!isValidPassword) {
      setInputError('Password must meet the following requirements')
    } else if (data.currentPassword !== data.newPassword) {
      try {
        const body = {
          ...data,
          token: localStorage.getItem('token'),
        }
        const resp = authPostForm(ENDPOINTS.updatePassword, body)
        resp
          .then(r => {
            console.log(r)
            resetInputFields()
            setPasswordUpdated(true)
          })
          .catch(error => {
            console.log(error)
            if (error?.response?.data === 'currentPassword.incorrect')
              setError('The current password you have provided is incorrect')
            else setError('Something went wrong, please try again.')
          })
      } catch (error) {
        setError('Something went wrong, please try again.')
        console.log(error)
      }
    } else {
      setError("You've used that password before. Please choose a different one.")
    }
  }

  return (
    <div className={styles.base} data-testid="update-password-form">
      <div className={styles.card}>
        <CardTitle>Update your password.</CardTitle>
        <div>{user.email}</div>
        <div className={styles.formContainer}>
          <form className={styles.form}>
            <FormInput
              onChange={handleInputChange}
              name="currentPassword"
              label="Current password"
              value={data.currentPassword}
              type="password"
            />
            <FormInput
              onChange={handleInputChange}
              name="newPassword"
              label="New password"
              value={data.newPassword}
              type="password"
              error={inputError}
            />
            <PasswordRequirements password={data.newPassword} />
            {error && (
              <div className={styles.error} data-testid="form-error">
                {error}
              </div>
            )}
            <div className={styles.btnContainer}>
              <Button
                title="Save password"
                type="button"
                onClick={handleSubmit}
                disabled={(formSubmitted && !error) || !data.newPassword || !data.currentPassword}
                data-testid="submit"
              />
            </div>

            {passwordUpdated && (
              <div>
                <div>Success! Your password was successfully changed</div>
              </div>
            )}
          </form>
        </div>
      </div>

      <br />
    </div>
  )
}

export default UpdatePassword
