import { useState } from 'react'
import ReCaptcha from 'components/reCaptcha/ReCaptcha'
import { postForm, ENDPOINTS } from 'services/api'
import CardTitle from 'components/cardTitle/CardTitle'
import FormInput from 'components/formInput/FormInput'
import Button from 'components/button/Button'
import styles from './AccountRecovery.module.scss'

function AccountRecovery(): JSX.Element {
  const [email, setEmail] = useState('')
  const [recoveryEmailSent, setRecoveryEmailSent] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false)

  const handleInputChange = (event: any) => {
    setSubmitDisabled(false)
    setEmail(event.target.value)
  }

  const handleSubmit = () => {
    setSubmitDisabled(true)
    const resp = postForm(ENDPOINTS.accountRecovery, { email: email })
    resp
      .then(r => {
        console.log(r)
        setRecoveryEmailSent(true)
      })
      .catch(error => {
        setSubmitDisabled(false)
        setError('An error occurred. Please try again.')
        console.log(error)
      })
  }

  return (
    <div className={styles.base}>
      {recoveryEmailSent && (
        <div className={styles.card}>
          <CardTitle data-testid="loginForm">
            {' '}
            Account recovery email sent.
          </CardTitle>
          <div className={styles.cardContent}>
            <p>
              Account recovery email sent to <b>{email}</b>
            </p>
            <p>
              If you don’t see this email in your inbox within 15 minutes, look for
              it in your junk mail folder.
            </p>
            <p>If you find it there, please mark it as “Not Junk”.</p>
          </div>
        </div>
      )}
      {!recoveryEmailSent && (
        <div className={styles.card}>
          <CardTitle data-testid="loginForm">Find Your Account.</CardTitle>
          <div className={styles.cardContent}>
            <div>
              Please enter your email address and we’ll send you a recovery link.
            </div>

            <form className={styles.form}>
              <FormInput
                onChange={handleInputChange}
                name="email"
                label="Email"
                value={email}
              />
              {error && (
                <div className={styles.error} data-testid="error-text">
                  {error}
                </div>
              )}
              <div className={styles.recaptcha}>
                <ReCaptcha onChange={() => setCaptchaVerified(true)} />
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  title="Send recovery email"
                  type="submit"
                  disabled={!email || submitDisabled || !captchaVerified}
                  loading={submitDisabled}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <br />
    </div>
  )
}

export default AccountRecovery
