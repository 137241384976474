import { useEffect, useRef, useState } from 'react'
import TextWordSpliterInput from '../textWordSpliterInput/TextWordSpliterInput'
import { Option } from './types'
import styles from './MultiSelect.module.scss'

type Props = {
  options: Option[]
  onSelectChange: Function
  title: string
  optionName: string
  optionNamePlural: string
  hasKeywordFilter?: boolean
  onKeywordFilterChange?: Function
  forceAllExpanded?: boolean
  suggestedWords?: string[] | undefined
  onOpen?: Function
  hasSelectAllOption?: boolean
  preselectedIds?: string[]
  preselectAllOptions?: boolean
}

function MultiSelect({
  options,
  onSelectChange,
  title,
  optionName,
  optionNamePlural,
  hasKeywordFilter = false,
  onKeywordFilterChange = () => {},
  forceAllExpanded = false,
  suggestedWords,
  onOpen = () => {},
  hasSelectAllOption = true,
  preselectAllOptions = false,
}: Props) {
  const thisRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([])
  const [expandedParents, setExpandedParents] = useState<(string | number)[]>([])
  const [filterInputVal, setFilterInputVal] = useState<string>('')

  const numberOfOptions = () => flattenNestedIfAvailable(options).length
  const isAllSelected = () => selectedValues.length >= numberOfOptions()

  useEffect(() => {
    if (preselectAllOptions) addOptionsToSelected(options)
  }, [])

  // watch selected values and return them when they change
  useEffect(() => {
    onSelectChange(selectedValues)
  }, [selectedValues, preselectAllOptions])

  useEffect(() => {
    if (isOpen) onOpen()
  }, [isOpen, onOpen])

  //handle closing the dropdown when a click outside
  const handleClickOutside = (ev: Event) => {
    const eventTargetElement = ev.target as HTMLElement
    const eventTargetNode = eventTargetElement as Node

    if (eventTargetNode !== null && thisRef.current && !thisRef.current.contains(eventTargetNode)) {
      setIsOpen(false)
    }
  }

  // set up listener to check for clicks outside dropdown
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  // Get a text for the different number of selected options
  const getHeaderText = () => {
    const count = selectedValues.length

    if (count) {
      const pluralisedOptionName = count > 1 ? optionNamePlural : optionName

      return `${selectedValues.length} ${pluralisedOptionName} selected`
    } else {
      return title
    }
  }

  const flattenNestedIfAvailable = (opts: Option[]) => {
    let accum: (string | number)[] = []

    opts.forEach(option => {
      if (!option.disabled) {
        if (option.id && !option.children?.length) {
          accum.push(option.id)
        }

        if (option.children?.length) {
          const children = flattenNestedIfAvailable(option.children)
          accum = [...accum, ...children]
        }
      }
    })

    return accum
  }

  // add passed option list to the selected value IDs
  const addOptionsToSelected = (optionsToAdd: Option[]) => {
    setSelectedValues(flattenNestedIfAvailable(optionsToAdd))
  }

  // select and deselect all options
  const onSelectAllClick = () => {
    if (isAllSelected()) setSelectedValues([])
    else {
      addOptionsToSelected(options)
    }
  }

  // when option row ( or checkbox when parent) is clicked
  const onOptionClick = (clickedOption: Option) => {
    let updated = []

    if (clickedOption.children?.length) {
      const childIds = clickedOption.children.map(o => o.id)
      isSelected(clickedOption)
        ? (updated = selectedValues.filter(selected => !childIds.includes(selected)))
        : (updated = [...selectedValues, ...childIds])
    } else {
      updated = isSelected(clickedOption)
        ? selectedValues.filter(s => s !== clickedOption.id)
        : [...selectedValues, clickedOption.id]
    }

    setSelectedValues(updated)
  }

  // returns class name if all or partial selction
  const selectAllClassName = () => {
    let toReturn = ''
    if (selectedValues.length > 0) toReturn = styles.partialSelected
    if (isAllSelected()) toReturn = styles.allSelected

    return toReturn
  }

  // expand to see the child options when parent row clicked
  const expandParentClick = (op: Option) => {
    const parentindex = expandedParents.indexOf(op.id)

    const updated = parentindex > -1 ? expandedParents.filter(p => p !== op.id) : [...expandedParents, op.id]

    console.log(updated)
    setExpandedParents(updated)
  }

  // is a parent row expanded to show children
  const isExpanded = (op: Option) => expandedParents.indexOf(op.id) > -1 || forceAllExpanded

  // is a check box in the list of selcted options
  const isSelected = (op: Option) => {
    if (op.children?.length) {
      const childIds = op.children.map(o => o.id)

      let allIdsSelected = childIds.every(id => selectedValues.indexOf(id) > -1)

      return allIdsSelected
    } else {
      return selectedValues.indexOf(op.id) > -1
    }
  }

  // Markup for the option rows
  const getOptionRow = (op: Option) => {
    const isParent = op.children?.length
    return (
      <div key={`${op.id}_${op.name}`}>
        {op.disabled ? (
          <div className={[styles.optionRow, styles.optionDisabled, isParent ? styles.isParent : ''].join(' ')}>
            <input type="checkbox" disabled className={styles.checkBox} />
            {op.name}
          </div>
        ) : (
          <div
            id={op.id.toString()}
            className={[styles.optionRow, isParent ? styles.isParent : '', isExpanded(op) ? styles.isOpen : ''].join(' ')}
            onClick={ev => {
              ev.stopPropagation()
              if (isParent) {
                expandParentClick(op)
              } else onOptionClick(op)
            }}>
            <input
              type="checkbox"
              className={styles.checkBox}
              checked={isSelected(op)}
              onChange={() => {}}
              onClick={ev => {
                console.log(op.id + ' -> ' + op.name)
                if (isParent) {
                  ev.stopPropagation()
                  onOptionClick(op)
                }
              }}
            />
            {op.name}
          </div>
        )}

        {op.children?.length && isExpanded(op) && (
          <div className={styles.optionRowChildren}>{op.children.map(opChild => getOptionRow(opChild))}</div>
        )}
      </div>
    )
  }

  const _onFilterInputChange = (val: string) => {
    setFilterInputVal(val)
    onKeywordFilterChange(val)
  }

  const onSuggestedWordClick = (word: string) => {
    let split = filterInputVal.split(' ')
    split[split.length - 1] = word
    const newVal = split.join(' ')

    _onFilterInputChange(newVal + ' ')
  }

  return (
    <div className={styles.base} ref={thisRef}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        {getHeaderText()}
      </div>

      {hasKeywordFilter && isOpen && (
        <TextWordSpliterInput filterInputVal={filterInputVal} onFilterValueChange={_onFilterInputChange} />
      )}

      {isOpen && (
        <div className={styles.suggestedWordsContainer}>
          {suggestedWords?.slice(0, 5).map(s => (
            <div key={s} className={styles.suggestedWord} onClick={() => onSuggestedWordClick(s)}>
              {s}
            </div>
          ))}
        </div>
      )}
      {options && (
        <div className={styles.optionsContainer}>
          <div className={[styles.optionsContainerInner, isOpen ? styles.isOpen : styles.isClosed].join(' ')}>
            {hasSelectAllOption && (
              <div className={[styles.optionRow, styles.selectAllRow].join(' ')} onClick={() => onSelectAllClick()}>
                <input
                  type="checkbox"
                  className={[styles.checkBox, selectedValues.length > 0 ? selectAllClassName() : ''].join(' ')}
                  checked={isAllSelected()}
                  onChange={() => {}}
                />
                Select all
              </div>
            )}

            {options.map(op => {
              return getOptionRow(op)
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default MultiSelect
