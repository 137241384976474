import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import styles from './DownloadReportPage.module.scss'
import ReportDownloader from '../../reportDownloader/ReportDownloader'

type ParamsType = {
  id: string
}

function DownloadReportPage() {
  const { id } = useParams<ParamsType>() || ''

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        <div className={styles.subtitle}>
          We have compared your input text against 136 million innovations, upto 21 territories.
        </div>

        {id && <ReportDownloader id={id} />}
      </div>
    </div>
  )
}

export default DownloadReportPage
