import { put, takeLatest, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportData } from './types'

//Each slice file should define a type for its initial state value, so that createSlice can correctly infer the type of
//state in each case reducer.
//All generated actions should be defined using the PayloadAction<T> type from Redux Toolkit, which takes the type
//of the action.payload field as its generic argument.

/**
 * Shape of the stores state
 */

export type ReportDataRequest = {
  id: string
  limit?: number
  isPdfPrinter?: boolean
  regenerate?: boolean
}

type loadingTypes = {
  reportData: boolean
  downloadPdf: boolean
}

type errorTypes = {
  searchStatus: string | null
  reportData: string | null
  downloadPdf: string | null
}

type ErrorKeyValueType = {
  key: keyof errorTypes
  value: null | string
}

type LoadingKeyValueType = {
  key: keyof loadingTypes
  value: boolean
}

export interface DashboardState {
  reportData: ReportData | null
  loading: loadingTypes
  error: errorTypes
}

/**
 * Initial value(s) of the stores state
 */
const initialState: DashboardState = {
  reportData: null,
  loading: {
    reportData: false,
    downloadPdf: false,
  },
  error: {
    searchStatus: null,
    reportData: null,
    downloadPdf: null,
  },
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchReportData: (state, action: PayloadAction<ReportDataRequest>) => {},
    setReportData: (state, action: PayloadAction<ReportData | null>) => {
      state.reportData = action.payload
    },
    downloadPdf: (
      state,
      action: PayloadAction<{
        searchId: string
        pdfFileName: string
      }>
    ) => {},
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
    setError: (state, action: PayloadAction<ErrorKeyValueType>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeLatest(actions.fetchReportData, fetchReportDataSaga)
  yield takeLatest(actions.downloadPdf, downloadPdfSaga)
}

/**
 * Sagas
 */

const setLoadingTrue = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: true }))
}

const setLoadingFalse = function* (key: keyof loadingTypes) {
  yield put(actions.setLoading({ key: key, value: false }))
}

const fetchReportDataSaga = function* (action: PayloadAction<ReportDataRequest>) {
  try {
    yield setLoadingTrue('reportData')
    yield put(actions.setReportData(null))
    yield put(actions.setError({ key: 'reportData', value: null }))
    const searchId = action.payload.id
    const isPdfPrinter = Boolean(action.payload.isPdfPrinter)
    const body = {
      ...(action.payload.limit && { limit: action.payload.limit }),
      ...(action.payload.regenerate && { regenerate: action.payload.regenerate }),
    }
    const response: AxiosResponse<ReportData> = yield call(Api.authPost, Api.ENDPOINTS.reportData(searchId, isPdfPrinter), body)
    if (response.status === 200) {
      const resp = response.data
      yield put(actions.setReportData(resp))
    }
    yield setLoadingFalse('reportData')
    console.log(response.data)
  } catch (error: any) {
    console.log(error?.response?.message)
    yield setLoadingFalse('reportData')
    yield put(actions.setError({ key: 'reportData', value: error?.message }))
  }
}

const downloadPdfSaga = function* (
  action: PayloadAction<{
    searchId: string
    pdfFileName: string
  }>
) {
  try {
    yield setLoadingTrue('downloadPdf')
    const searchId = action.payload.searchId
    const response: AxiosResponse<any> = yield call(Api.getBlob, Api.ENDPOINTS.reportPDF(searchId), {})
    const filename = action.payload.pdfFileName + '.pdf'
    yield setLoadingFalse('downloadPdf')

    blobToPdf(response.data, filename)
  } catch (error: any) {
    yield put(actions.setError({ key: 'downloadPdf', value: error?.message }))
    yield setLoadingFalse('downloadPdf')
    console.log(error)
  }
}

function blobToPdf(blob: any, reportName: any) {
  const file = new Blob([blob], { type: 'application/pdf' })
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = reportName
  link.click()
}
