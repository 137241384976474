import {Link} from 'react-router-dom'
import Title from 'components/title/Title'
import CardTitle from 'components/cardTitle/CardTitle'
import styles from './BetaProgramme.module.scss'

function BetaProgramme() {
  return (
    <div className={`${styles.base} ${styles.bigHeader}`}>
      <Title>Sign up for the beta programme.</Title>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <CardTitle small>Registration is currently disabled</CardTitle>
          <div className={styles.heading}>
            Only 40 beta user <br />
            places available.
          </div>
          <div className={styles.text}>
            Go to
            <br />
            <a href='https://www.elute.io/investorinsights'>
              <b>www.elute.io/investorinsights</b>
            </a>
            <br />
            and learn how you can gain
            <br />
            <b>
              free access and
              <br />
              unlimited reports.
            </b>
          </div>
          <div className={styles.line}></div>
          <div className={styles.existingUsers}>
            Existing users log in <Link to='/login'>here</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BetaProgramme
