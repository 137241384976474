import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useNavigate, Link } from 'react-router-dom'
import useInterval from 'use-interval'
import { BsExclamationTriangle as ErrorIcon } from 'react-icons/bs'

import { useAppDispatch } from 'store/hooks'
import { actions as searchActions, selectors } from 'store/search'
import { actions as systemActions } from 'store/system'
import { RootState } from 'store/'
import { ErrorDetails } from 'store/system/types'
import { ImSpinner9 as Spinner } from 'react-icons/im'
import Button from 'components/button/Button'
import LaptopImg from 'assets/images/laptop-coffe.png'

import styles from './SearchWaitPage.module.scss'

type ParamsType = {
  id: string
}

type Stage = {
  time: number
  stage: string
  description?: string
  numOfPatents?: string
  cummulative?: string
}

const stages: Stage[] = require('assets/json/reportStages/stages.json')

function SearchWaitPage() {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const searches = useSelector((state: RootState) => state.search.searches)
  const searchStatus = useSelector((state: RootState) => state.search.searchStatus)
  const error = useSelector(selectors.generalError)
  const [reportStage, setReportStage] = useState<Stage>({
    time: 0,
    stage: '',
  })
  const [start, setStart] = useState(false)
  const [searchTooLong, setSearchTooLong] = useState(false)
  const { id } = useParams<ParamsType>() || ''

  useEffect(() => {
    dispatch(searchActions.clearGeneralError())
  }, [dispatch])

  useEffect(() => {
    let timer: any = null
    if (start && searchStatus && !['search_finished', 'error'].includes(searchStatus)) {
      timer = setTimeout(() => {
        console.log('search is too long')
        setStart(false)
        setSearchTooLong(true)
        const data: ErrorDetails = {
          searchId: searches[0].ID,
          errorType: 'SEARCH_TOO_LONG',
        }
        dispatch(systemActions.sendError(data))
      }, 12 * 60 * 1000) //12 minutes
    }

    return () => clearTimeout(timer)
  }, [start, searchStatus, dispatch, searches])

  useEffect(() => {
    const updateStage: any = (index: number) => {
      if (index < stages.length) {
        setReportStage(stages[index])
        const nextIndex = index + 1
        setTimeout(() => updateStage(nextIndex), stages[index].time * 1000)
      }
    }
    if (start) updateStage(0)
  }, [start])

  useInterval(
    () => {
      const searchId = searches[0].ID
      dispatch(searchActions.getSearchStatus(searchId))
    },
    start ? 6000 : null
  ) // passing null will cancel the interval if it is already running

  useEffect(() => {
    if (searches.length > 0) {
      setStart(true)
      history(`/report/${searches[0].ID}`, { replace: true })
    }
    if (searches.length === 0 && id) {
      history(`/report/${id}`)
      dispatch(searchActions.fetchSearches(id))
    }
  }, [searches, id, dispatch, history])

  useEffect(() => {
    if (isSearchFinished()) {
      setReportStage({
        time: 0,
        stage: 'Your report is ready to be downloaded.',
      })
      setStart(false)
    } else if (searchStatus === 'error') {
      setStart(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStatus])

  const isSearchFinished = () => {
    return ['search_finished', 'report_compiling', 'complete'].includes(searchStatus!)
  }

  return (
    <div className={styles.base}>
      <div className={styles.first}>
        <div className={styles.content}>
          <div className={styles.title}>
            Grab a coffee, whilst we prepare your report. We'll email you when it's ready with a link to the the report.
          </div>
          <div className={styles.imageContainer}>
            <img src={LaptopImg} alt="Logo" />
          </div>
        </div>
      </div>
      <div className={styles.second}>
        <div className={styles.subtitle}>We compare your input text against 136 million innovations, up to 21 territories.</div>

        <div className={styles.progressCard}>
          {error ? (
            <div className={styles.error}>{error}</div>
          ) : (
            <>
              {!isSearchFinished() && (

                <>
                  {searchTooLong ? (
                    <div className={styles.error}>
                      <div className={styles.errorIcon}>
                        <ErrorIcon />
                      </div>
                      <div>The search seems to be taking longer than normal, please refresh this page.</div>
                      <br />
                      <div>If it happens again then contact us and we’ll fix it.</div>
                    </div>
                  ) : (
                    <>
                      <h2>{reportStage.stage}</h2>
                      <div>{reportStage.description}</div>
                      <div>{reportStage.numOfPatents}</div>
                      <div className={styles.cummulativeText}>{reportStage.cummulative}</div>

                      <Spinner className={styles.spinner} />
                    </>
                  )}
                </>
              )}

              {isSearchFinished() && (
                <>
                  <h2>Search is Ready</h2>
                  <div className={styles.cummulativeText}>136+ million records checked</div>
                  <div className={styles.buttonContainer}>
                    <Link to={`/dashboard/reporting/report/${id}`}>
                      <Button title="Get report" type="submit" big />
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchWaitPage
