import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Link } from 'react-router-dom'
import { BiLike } from 'react-icons/bi'
import { FiUserCheck as UserCheck } from 'react-icons/fi'
import { BiEnvelope as EnvelopeIcon } from 'react-icons/bi'

import Disclaimer from 'components/disclaimer/Disclaimer'

import Title from 'components/title/Title'
import CardTitle from 'components/cardTitle/CardTitle'
import Button, { STYLE } from 'components/button/Button'
import styles from './StartPage.module.scss'

function StartPage() {
  const firstName = useSelector((state: RootState) => state.account.firstName)
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false)

  return (
    <div className={styles.base}>
      <Title>
        <>
          Welcome {firstName}!
          <br />
          Let's get started, so please select.
        </>
      </Title>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.customCardTitle}>
            <CardTitle>Create a new report.</CardTitle>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.paragraph}>
              <div className={styles.likeIcon}>
                <BiLike />
              </div>
              <div>
                I'm a returning user. <br />I know what I need to provide.
              </div>
            </div>
            <div className={styles.paragraph}>
              <div className={styles.likeIcon}>
                <BiLike size="2em" />
              </div>
              <div>
                I know the company I want to investigate. I have one of their patent's which I will use
                as search material.
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <Link to="/guidance">
                <Button title="Begin the search" style={STYLE.green} type="button" onClick={() => {}} />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.customCardTitle}>
            <CardTitle>What do I need?</CardTitle>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.paragraph}>
              You need one of the Company’s patents. To begin a search you will need to use the 'Claims'
              section of the patent.
            </div>
            <div className={styles.buttonContainer}>
              <a target="_blank" href="https://www.elute.io/how-to-use-investor-insights" rel="noreferrer">
                <Button title="How to use Investor Insights" type="button" style={STYLE.orange} />
              </a>
            </div>
            <div className={styles.paragraph}>
              if you don’t have the Claims text or just want search with Company Name, click below.
            </div>

            <div className={styles.buttonContainer}>
              {/* <a target="_blank" href="" rel="noreferrer"> */}
              <Button title="Search with Company Name (Coming Soon)" type="button" style={STYLE.orange} tooltip={"Coming Soon"} disabled={true}/>
              {/* </a> */}
            </div>
          </div>
        </div>

        <div className={`${styles.card} ${styles.card3}`}>
          <div className={styles.customCardTitle}>
            <CardTitle>Or, ask for it.</CardTitle>
          </div>
          <div className={styles.paragraph}>
            <div className={styles.icon}>
              <UserCheck />
            </div>
            <div>
              Maybe you’re not sure where to get your search text?  Ask the company of interest to
              provide the information.  This should be easy for them to provide.
            </div>
          </div>
          <div className={styles.paragraph}>
            <div className={styles.icon}>
              <EnvelopeIcon />
            </div>
            <div>
              Send them an email asking for the input text needed.
              <br />
              <br />
              Take a look at
              <Link to="https://www.elute.io/where-are-patent-claims"> What are patent claims </Link>
              so you know what to ask for.
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.disclaimerLink}
        onClick={() => {
          setShowDisclaimer(true)
        }}>
        Beta disclaimer and beta brand (link)
      </div>
      {showDisclaimer && (
        <div className={styles.disclaimerContainer}>
          <Disclaimer
            show={true}
            onClose={() => {
              setShowDisclaimer(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default StartPage
