import {useState} from 'react'
import {useAppDispatch} from 'store/hooks'
import {actions as compareActions} from 'store/compare'
//import ProgressTracker from 'containers/compare/component/progressTracker/ProgressTracker'
import SearchTextStep from './containers/searchTextStep/SearchTextStep'
import SearchDocumentsStep from './containers/searchDocumentsStep/SearchDocumentsStep'
import ResultsStep from './containers/resultsStep/ResultsStep'
import styles from './Compare.module.scss'

function Compare() {
  const dispatch = useAppDispatch()
  const [step, setStep] = useState<string>('searchText')

  const handleSetNextStep = (stepName: string) => {
    setStep(stepName)
  }

  const handleNewSearch = () => {
    setStep('searchText')
    dispatch(compareActions.resetSearchTexts())
    dispatch(compareActions.resetSearchDocuments())
  }

  return (
    <div className={styles.base}>
      {step !== 'results' && (
        <div className={styles.stepsContainer}>
          {/* <ProgressTracker step={step} /> */}
          <div className={styles.content}>
            {step === 'searchText' && <SearchTextStep setNextStep={(step) => handleSetNextStep(step)} />}
            {step === 'searchDocuments' && <SearchDocumentsStep setNextStep={(step) => handleSetNextStep(step)} />}
          </div>
        </div>
      )}
      {step === 'results' && <ResultsStep newSearch={() => handleNewSearch()} />}
    </div>
  )
}

export default Compare
