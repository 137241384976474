import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Category } from 'containers/search/types'
import styles from './CategoryPicker.module.scss'
import './CategoryStyles.css'
import MultiSelect from 'components/multiselect/MultiSelect'
import { Option } from 'components/multiselect/types'
import axios from 'axios'
import { useEffect, useState } from 'react'

type Props = {
  onSelectChange: Function
  unfilteredCategoriesLevel1: Category[]
  unfilteredCategoriesLevel2: Category[]
  onOpen: Function
}

type Suggestions = {
  categories?: Map<String, String[]>
  words?: string[]
}

function CategoryPicker({ onSelectChange, unfilteredCategoriesLevel1, unfilteredCategoriesLevel2, onOpen = () => {} }: Props) {
  const roles = useSelector((state: RootState) => state.account.roles)
  const [suggestions, setSuggestions] = useState<Suggestions>(Object({}))
  const [categoriesLevel1, setCategoriesLevel1] = useState<Category[]>(unfilteredCategoriesLevel1)
  const [categoriesLevel2, setCategoriesLevel2] = useState<Category[]>(unfilteredCategoriesLevel2)
  const [optionsForMultiselect, setOptionsForMultiselect] = useState<Option[]>([])
  const [filter, setFilter] = useState<string>('')
  const [categoriesEnabled, setCategoriesEnabled] = useState(false)
  const enabledCategoryIds = ['AAAA', 'A61']

  useEffect(() => {
    setOptionsForMultiselect(getNestedOptions())
  }, [categoriesLevel1, categoriesLevel2, categoriesEnabled])

  useEffect(() => {
    setCategoriesLevel1(unfilteredCategoriesLevel1)
    setCategoriesLevel2(unfilteredCategoriesLevel2)
  }, [unfilteredCategoriesLevel1, unfilteredCategoriesLevel2])

  const getNestedOptions = (): Option[] => {
    if (categoriesLevel1 && categoriesLevel1.length > 0) {
      const options = categoriesLevel1
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(parent => {
          let childrenFormatted: Option[] = []

          if (categoriesLevel2 && categoriesLevel2.length > 0) {
            const childrenFiltered = categoriesLevel2?.filter(cat => cat.parent === parent.id)
            childrenFormatted = childrenFiltered
              .map(c => {
                return { id: c.id, name: c.name }
              })
              .sort((a, b) => (a.name > b.name ? 1 : -1))
          }

          let toReturn: Option = {
            id: parent.id,
            name: parent.name,
          }

          if (childrenFormatted.length) {
            toReturn.children = childrenFormatted
          }

          return toReturn
        })
      if (categoriesEnabled) return options
      else return disableNonAvailableOptions(options)
    } else return []
  }

  const disableNonAvailableOptions = (options: Option[]): Option[] => {
    const processOptions = (options: Option[]): Option[] => {
      return options.map(op => {
        const isOptionEnabled = enabledCategoryIds.includes(op.id.toString())
        const updatedOp: Option = {
          ...op,
          disabled: !isOptionEnabled,
        }

        if (op.children) {
          updatedOp.children = processOptions(op.children)
        }

        return updatedOp
      })
    }

    return processOptions(options)
  }

  const keywordFilterChange = (value: string) => {
    if (value?.length > 1) {
      let data = `{"terms" : "${value}"}`
      axios
        .post(String(process.env.REACT_APP_CATEGORY_SUGGEST_URL), data)
        .then(response => {
          setSuggestions(response.data)
        })
        .catch(err => console.log(err))
    } else {
      setSuggestions({})
    }

    setFilter(value)
  }

  useEffect(() => {
    if (filter.length <= 1) {
      setCategoriesLevel1(unfilteredCategoriesLevel1)
      setCategoriesLevel2(unfilteredCategoriesLevel2)
      return
    }

    let cat1Filtered: Category[] = []
    let cat2Filtered: Category[] = []

    for (const sugg in suggestions?.categories) {
      let cat1FilteredSub = unfilteredCategoriesLevel1.filter(c => {
        if (cat1Filtered.indexOf(c) > -1) return false // Don't add duplicate
        return (
          c.id
            .toString()
            .split(',')
            .map(cs => cs.trim())
            .indexOf(sugg) > -1
        )
      })

      let cat2FilteredSub = unfilteredCategoriesLevel2.filter(c => {
        if (cat2Filtered.indexOf(c) > -1) return false // Don't add duplicate
        return (
          c.id
            .toString()
            .split(',')
            .map(cs => cs.trim())
            .indexOf(sugg) > -1
        )
      })
      cat1Filtered.push(...cat1FilteredSub)
      cat2Filtered.push(...cat2FilteredSub)
    }

    cat2Filtered.forEach(cat => {
      let parents = unfilteredCategoriesLevel1.filter(p => {
        if (!cat.parent) return false

        return (
          p.id
            .toString()
            .split(',')
            .map(p => p.trim())
            .indexOf(cat.parent.toString()) > -1
        )
      })

      parents.forEach(p => (cat1Filtered.indexOf(p) === -1 ? cat1Filtered.push(p) : null))
    })

    setCategoriesLevel1(cat1Filtered)
    setCategoriesLevel2(cat2Filtered)
  }, [suggestions])

  return (
    <div className={styles.base}>
      {roles?.includes('bo_admin') && (
        <div className={styles.boAdminCheckbox}>
          <input type="checkbox" checked={categoriesEnabled} onChange={() => setCategoriesEnabled(!categoriesEnabled)} />
          Enable all categories (bo admins)
        </div>
      )}
      <div className={styles.categoryFormContainer}>
        {optionsForMultiselect.length > 0 && (
          <MultiSelect
            options={optionsForMultiselect}
            onSelectChange={onSelectChange}
            title="Select one or more categories"
            optionName="category"
            optionNamePlural="categories"
            hasKeywordFilter={true}
            onKeywordFilterChange={(value: string) => keywordFilterChange(value)}
            forceAllExpanded={suggestions?.categories ? Object.keys(suggestions?.categories).length > 0 : false}
            suggestedWords={suggestions?.words}
            onOpen={onOpen}
            hasSelectAllOption={true}
            preselectAllOptions={true}
          />
        )}
      </div>
    </div>
  )
}

export default CategoryPicker
