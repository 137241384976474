import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { User, Search, Result } from './types'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportRequest, ReportResponse } from 'store/search/types'

type SearchIdResults = {
  searchId: string
  results: Result[]
}

type UserDetails = {
  id: string
  user?: User
  searches?: Search[]
}

type loadingTypes = {
  searches: boolean
  results: boolean
  export: boolean
  exportForAnalysis: boolean
  generateReport: boolean | string
}

type errorTypes = 'user' | 'searches' | 'results' | 'generateReport'

type LoadingKeyValueType = {
  key: keyof loadingTypes
  value: boolean
}

type errorKeyValueType = {
  key: errorTypes
  value: null | string
}

type DashboardState = {
  users: User[]
  userDetails: UserDetails
  loading: loadingTypes
  error: {
    user: string | null
    searches: string | null
    results: string | null
    generateReport: string | null
  }
}

/**
 * Initial value(s) of the stores state
 */
const initialState: DashboardState = {
  users: [],
  userDetails: {
    id: '',
  },
  loading: {
    searches: false,
    results: false,
    export: false,
    exportForAnalysis: false,
    generateReport: false,
  },
  error: {
    user: null,
    searches: null,
    results: null,
    generateReport: null,
  },
}

/**
 * State initalisation and reducer definitions
 * If being watched as a saga, define as an empty function
 */
const slice = createSlice({
  name: 'adminArea',
  initialState,
  reducers: {
    getUsers: (state, action: PayloadAction<string>) => {},
    getUser: (state, action: PayloadAction<string>) => {},
    getUserSearches: (state, action: PayloadAction<string>) => {},
    getUserSearchResults: (state, action: PayloadAction<UserSearch>) => {},
    exportResults: (state, action: PayloadAction<UserSearch>) => {},
    exportResultsForAnalysis: (state, action: PayloadAction<UserSearch>) => {},
    generateUserReport: (state, action: PayloadAction<ReportRequest>) => {},
    setUsers: (state, action: PayloadAction<Array<User>>) => {
      state.users = action.payload
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.userDetails.id = action.payload.id
      state.userDetails.user = action.payload
    },
    setUserSearches: (state, action: PayloadAction<{ id: string; searches: Search[] }>) => {
      state.userDetails.id = action.payload.id
      state.userDetails.searches = action.payload.searches
    },
    setUserSearchResult: (state, action: PayloadAction<SearchIdResults>) => {
      if (state.userDetails && state.userDetails.searches) {
        const searchIndex: number = state.userDetails.searches.findIndex(search => search.ID === action.payload.searchId)
        state.userDetails.searches[searchIndex].results = action.payload.results
      }
    },
    setError: (state, action: PayloadAction<errorKeyValueType>) => {
      const { key, value } = action.payload
      state.error[key] = value
    },
    setLoading: (state, action: PayloadAction<LoadingKeyValueType>) => {
      const { key, value } = action.payload
      state.loading[key] = value
    },
    setGenerateReportLoading: (state, action: PayloadAction<boolean | string>) => {
      state.loading.generateReport = action.payload
    },
    setExportLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.export = action.payload
    },
    setExportForAnalysisLoading: (state, action: PayloadAction<boolean>) => {
      state.loading.exportForAnalysis = action.payload
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.getUsers, getUsersSaga)
  yield takeEvery(actions.getUser, getUserSaga)
  yield takeEvery(actions.getUserSearches, getUserSearchesSaga)
  yield takeEvery(actions.getUserSearchResults, getUserSearchResultsSaga)
  yield takeEvery(actions.exportResults, exportResultsSaga)
  yield takeEvery(actions.exportResultsForAnalysis, exportResultsForAnalysisSaga)
  yield takeLatest(actions.generateUserReport, generateUserReportSaga)
}

/**
 * Sagas
 */
export const getUsersSaga = function* (action: PayloadAction<string>) {
  try {
    const productName = action.payload
    const response: AxiosResponse<User[]> = yield call(Api.authGet, `${Api.ENDPOINTS.users}/?product=${productName}`, {})
    yield put(actions.setUsers(response.data))
  } catch (error: any) {
    console.log(error)
  }
}

export const getUserSaga = function* (action: PayloadAction<string>) {
  try {
    const userId = action.payload
    const response: AxiosResponse<User> = yield call(Api.authGet, Api.ENDPOINTS.user(userId), {})
    const user: User = response.data
    user.id = userId
    yield put(actions.setUser(user))
  } catch (error: any) {
    yield put(actions.setError({ key: 'user', value: error?.message }))
    console.log(error)
  }
}

export const getUserSearchesSaga = function* (action: PayloadAction<string>) {
  const userId = action.payload
  yield put(actions.setError({ key: 'searches', value: null }))
  yield put(actions.setLoading({ key: 'searches', value: true }))
  try {
    const response: AxiosResponse<Search[]> = yield call(Api.authGet, Api.ENDPOINTS.userSearches(userId), {})
    yield put(actions.setLoading({ key: 'searches', value: false }))
    yield put(actions.setUserSearches({ id: userId, searches: response.data }))
  } catch (error: any) {
    yield put(actions.setLoading({ key: 'searches', value: false }))
    yield put(actions.setError({ key: 'searches', value: error?.message }))
    console.log(error)
  }
}
export type UserSearch = {
  userId: string
  searchId: string
}

export const getUserSearchResultsSaga = function* (action: PayloadAction<UserSearch>) {
  yield put(actions.setError({ key: 'results', value: null }))
  yield put(actions.setLoading({ key: 'results', value: true }))
  try {
    const response: AxiosResponse<Result[]> = yield call(
      Api.authGet,
      Api.ENDPOINTS.userSearchResults(action.payload.userId, action.payload.searchId),
      { limit: 50 }
    )
    const data: SearchIdResults = {
      searchId: action.payload.searchId,
      results: response.data,
    }
    yield put(actions.setLoading({ key: 'results', value: false }))
    yield put(actions.setUserSearchResult(data))
  } catch (error: any) {
    yield put(actions.setError({ key: 'results', value: error?.message }))
    yield put(actions.setLoading({ key: 'results', value: false }))
    console.log(error)
  }
}

export const exportResultsSaga = function* (action: PayloadAction<UserSearch>) {
  try {
    yield put(actions.setExportLoading(true))
    const data = { format: 'csv', numResults: 250 }
    const response: AxiosResponse<any> = yield call(
      Api.authPost,
      Api.ENDPOINTS.exportResults(action.payload.userId, action.payload.searchId),
      data
    )
    yield put(actions.setExportLoading(false))
    blobToFile(response.data, 'results', data.format)
  } catch (error: any) {
    yield put(actions.setExportLoading(false))
    console.log(error)
  }
}

export const exportResultsForAnalysisSaga = function* (action: PayloadAction<UserSearch>) {
  try {
    yield put(actions.setExportForAnalysisLoading(true))
    const data = {
      format: 'csv',
      numResults: 1000,
    }
    const response: AxiosResponse<any> = yield call(
      Api.authPost,
      Api.ENDPOINTS.exportResultsForAnalysis(action.payload.userId, action.payload.searchId),
      data
    )
    yield put(actions.setExportForAnalysisLoading(false))
    blobToFile(response.data, 'results', data.format)
  } catch (error: any) {
    yield put(actions.setExportForAnalysisLoading(false))
    console.log(error)
  }
}

function blobToFile(blob: any, filename: string, format: string) {
  const type =
    format === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv;charset=utf-8;'
  const file = new Blob([blob], {
    type: type,
  })
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.download = filename
  link.click()
}

const generateUserReportSaga = function* (action: any) {
  const userId = action.payload.userId
  const searchId: string = action.payload.id
  yield put(actions.setGenerateReportLoading(searchId))
  try {
    const response: AxiosResponse<ReportResponse> = yield call(
      Api.authPost,
      Api.ENDPOINTS.generateUserReport(userId, searchId),
      {}
    )
    const status: ReportResponse = {
      search_status: response.data.search_status,
      report_status: response.data.report_status,
    }
    yield put(actions.setGenerateReportLoading(false))
  } catch (e) {
    yield put(actions.setGenerateReportLoading(false))
    yield put(actions.setError({ key: 'generateReport', value: searchId }))
  }
}
