import { TopApplicant } from 'store/dashboard/types'
import tableStyles from './../Table.module.scss'
import styles from './CompaniesTable.module.scss'

type Props = {
  applicants: TopApplicant[]
}
const CompaniesTable = ({ applicants }: Props) => {
  return (
    <div className={styles.base}>
      <table className={tableStyles.base}>
        <thead>
          <tr>
            <th>Research</th>
            <th>Rank</th>
            <th>Assignee</th>
            <th>Origin</th>
            <th>Protected Territories</th>
            <th>Seeking Protection</th>
          </tr>
        </thead>
        <tbody>
          {!applicants && <td>No similar companies</td>}
          {applicants.map((applicant, index) => (
            <tr key={index} className={`${!applicant.research ? styles.commercial : ''}`}>
              <td>{applicant.research && 'Research'}</td>
              <td>{applicant.rank}</td>
              <td>
                {applicant.applicant_name}
                {applicant.applicant_list_names.length > 0 && (
                  <div className={styles.alternativeApplicantName}>
                    {applicant.applicant_list_names.map((altName, index) => (
                      <div key={index}>[{altName}]</div>
                    ))}
                  </div>
                )}
                {applicant.related_companies.map((related_company, index, array) => (
                  <div>
                    {related_company}
                    {index < array.length - 1 && ', '}
                  </div>
                ))}
              </td>
              <td>
                {applicant.origins.map((origin, index, array) => (
                  <>
                    {origin.toLocaleUpperCase()}
                    {index < array.length - 1 && ', '}
                  </>
                ))}
              </td>
              <td>
                {applicant.protected_territories.map((territory, index, array) => (
                  <>
                    {territory}
                    {index < array.length - 1 && ', '}
                  </>
                ))}
              </td>
              <td>
                {applicant.non_protected_territories.map((territory, index, array) => (
                  <>
                    {territory}
                    {index < array.length - 1 && ', '}
                  </>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompaniesTable
