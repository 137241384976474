import {FiCheckCircle as Check} from 'react-icons/fi'
import styles from './PasswordRequirements.module.scss'

export type PasswordTypeProps = {
  password: string
}
function PasswordRequirements({password}: PasswordTypeProps) {
  const atLeastOneLowerCase = () => /(?=.*[a-z])/.test(password)
  const atLeastOneUpperCase = () => /(?=.*[A-Z])/.test(password)
  const atLeastOneDigit = () => /(?=.*\d)/.test(password)

  return (
    <div className={styles.base}>
      <div className={styles.block}>
        <div className={`${styles.item} ${atLeastOneLowerCase() ? styles.done : ''}`}>
          <Check size='1em' />
          One lower case letter
        </div>
        <div className={`${styles.item} ${atLeastOneDigit() ? styles.done : ''}`}>
          <Check size='1em' /> One digit
        </div>
      </div>{' '}
      <div className={styles.block}>
        <div className={`${styles.item} ${atLeastOneUpperCase() ? styles.done : ''}`}>
          <Check size='1em' /> One upper case letter
        </div>
        <div className={`${styles.item} ${password.length > 7 ? styles.done : ''}`}>
          <Check size='1em' /> At least 8 letters
        </div>
      </div>
    </div>
  )
}

export default PasswordRequirements
