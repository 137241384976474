import { fork } from 'redux-saga/effects'

import { sagas as search } from './search'
import { sagas as companySearch } from './companySearch'
import { sagas as dashboard } from './dashboard'
import { sagas as compare } from './compare'
import { sagas as account } from './account'
import { sagas as system } from './system'
import { sagas as adminArea } from './adminArea'
import { sagas as pinger } from './pinger'

export default function* rootSaga() {
  yield fork(search)
  yield fork(companySearch)
  yield fork(dashboard)
  yield fork(compare)
  yield fork(account)
  yield fork(system)
  yield fork(adminArea)
  yield fork(pinger)
}
