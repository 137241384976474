import { takeEvery, call } from 'redux-saga/effects'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorDetails } from './types'

/**
 * Initial value(s) of the stores state
 */
const initialState = {}

/**
 * State initalisation and reducer definitions
 */
const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    sendError: (state, action: PayloadAction<ErrorDetails>) => {},
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.sendError, sendErrorSaga)
}

/**
 * Sagas
 */
export const sendErrorSaga = function* (action: PayloadAction<ErrorDetails>) {
  try {
    yield call(Api.authPost, Api.ENDPOINTS.systemError, action.payload)
  } catch (error: any) {
    console.log(error)
  }
}
