import { useEffect, useState } from 'react'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as companyActions } from 'store/companySearch'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Title from 'components/title/Title'
import CardTitle from 'components/cardTitle/CardTitle'
import FormInput from 'components/formInput/FormInput'
import Button from 'components/button/Button'

import styles from './CompanySearchPage.module.scss'
import Spinner from 'components/spinner/Spinner'

function CompanySearchPage() {
  const dispatch = useAppDispatch()
  const history = useNavigate()

  const suggestions = useSelector((state: RootState) => state.companySearch.suggestions)
  const searchResponse = useSelector((state: RootState) => state.companySearch?.searchResponse)
  const isLoading = useSelector((state: RootState) => state.companySearch.loading)
  const searchSubmitted = useSelector((state: RootState) => state.companySearch.loading.search)

  const error = useSelector((state: RootState) => state.companySearch.error)
  const [companyName, setCompanyName] = useState<string>('')
  const [companyNameError, setCompanyNameError] = useState<boolean>(false)
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])

  const handleInputChange = (value: string) => {
    if (value.length > 1) dispatch(companyActions.getSuggestedCompanies(value))
    else dispatch(companyActions.setSuggestedCompanies([]))
    const validSelectedCompanies = selectedCompanies.filter(option => suggestions.includes(option))
    setSelectedCompanies(validSelectedCompanies)
  }

  const handleSubmit = () => {
    dispatch(companyActions.submitCompanySearch(selectedCompanies))
  }

  const handleCheckboxChange = (option: string) => {
    if (selectedCompanies.includes(option)) {
      setSelectedCompanies(selectedCompanies.filter(item => item !== option))
    } else {
      setSelectedCompanies([...selectedCompanies, option])
    }
  }

  useEffect(() => {
    if (searchResponse) {
      history(`/company/search/${searchResponse?.ID}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResponse])

  type Props = {
    options: string[]
  }

  const MultiSelect = ({ options }: Props) => {
    return (
      <div className={styles.base1}>
        {options.map(option => (
          <div className={styles.row}>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={() => handleCheckboxChange(option)}
              checked={selectedCompanies.includes(option)}
            />
            {option}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.base}>
      <Title>Please fill out the following fields.</Title>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <CardTitle>Company Search</CardTitle>
          <div className={styles.cardContent}>
            <div className={styles.formInputContainer}>
              <FormInput
                onChange={event => {
                  handleInputChange(event?.target.value)
                  setCompanyNameError(false)
                  setCompanyName(event?.target.value)
                }}
                name="companyName"
                label="Select Company"
                value={companyName}
              />
              {isLoading.suggestions && <Spinner />}
              {suggestions?.length > 0 && <MultiSelect options={suggestions} />}
              {companyNameError && <div className={styles.error}>Required</div>}
              {error.suggestions && <div className={styles.error}>{error.suggestions}</div>}

              <div className={styles.buttonContainer}>
                <Button
                  title="Company search"
                  type="button"
                  disabled={searchSubmitted || selectedCompanies.length === 0}
                  loading={searchSubmitted}
                  big
                  onClick={() => handleSubmit()}
                />
                {error.search && <div className={styles.error}>{error.search}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySearchPage
