import { CgSpinner as SpinnerIcon } from 'react-icons/cg'
import styles from './Spinner.module.scss'

type Props = {
  size?: string | number | undefined
}
function Spinner({ size = 25 }: Props) {
  return <SpinnerIcon size={size} className={styles.spinner} />
}

export default Spinner
