import { takeEvery, call, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Status = {
  releaseDate: string
  maintenanceMode: boolean
}
const initialState = {
  releaseDate: '',
  maintenanceMode: false,
}

const slice = createSlice({
  name: 'pinger',
  initialState,
  reducers: {
    getMaintenanceStatus: state => {},
    setMaintenanceStatus: (state, action: PayloadAction<Status>) => {
      state.releaseDate = action.payload.releaseDate
    },
  },
})

export const actions = slice.actions

export const reducer = slice.reducer

export function* sagas() {
  yield takeEvery(actions.getMaintenanceStatus, getMaintenanceStatusSaga)
}

/**
 * Sagas
 */
export const getMaintenanceStatusSaga = function* () {
  try {
    const pinglyFile = '/pingly.json'
    const response: AxiosResponse<Status> = yield call(Api.getFrontend, pinglyFile)
    yield put(actions.setMaintenanceStatus(response.data))
  } catch (error: any) {
    console.log(error)
  }
}
