import { FaCircle, FaCircleHalfStroke, FaRegCircle } from 'react-icons/fa6'
import { Patent } from 'store/dashboard/types'
import styles from './InventionsTable.module.scss'
import tableStyles from './../Table.module.scss'
import { useState } from 'react'

type Props = {
  patents: Patent[]
}
const InventionsTable = ({ patents }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const MissingData = () => <div className={styles.missingData}>Info not supplied by data provider</div>

  type DotsProps = {
    normalizedRank: number
  }
  const Dots = ({ normalizedRank }: DotsProps) => (
    <div
      className={styles.dots}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      title={`${normalizedRank}`}>
      {showTooltip && <div className={styles.tooltip}>Normalized Rank: {normalizedRank}</div>}
      <Circle normalizedRank={normalizedRank} completeCircle={5} halfCircle={0} />
      <Circle normalizedRank={normalizedRank} completeCircle={15} halfCircle={10} />
      <Circle normalizedRank={normalizedRank} completeCircle={30} halfCircle={20} />
      <Circle normalizedRank={normalizedRank} completeCircle={75} halfCircle={40} />
    </div>
  )
  type CircleProps = {
    normalizedRank: number
    completeCircle: number
    halfCircle: number
  }
  const Circle = ({ normalizedRank, completeCircle, halfCircle }: CircleProps) => (
    <>{normalizedRank > completeCircle ? <FaCircle /> : normalizedRank > halfCircle ? <FaCircleHalfStroke /> : <FaRegCircle />}</>
  )

  const capitalizeFirstLetter = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(w => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ')
  }
  return (
    <div className={styles.base}>
      {patents ? (
        <table className={tableStyles.base}>
          <thead>
            <tr>
              <th>Rank</th>
              <th></th>
              <th>Assignee</th>
              <th>Title</th>
              <th>Priority Year</th>
              <th>Patent Number</th>
              <th>No. in family*</th>
            </tr>
          </thead>
          <tbody>
            {patents.length === 0 && <td>No similar patents in the top 100</td>}
            {patents.map((patent, index) => (
              <tr key={index}>
                <td>{patent.rank}</td>
                <td>
                  <Dots normalizedRank={patent.normalizedRank} />
                </td>
                <td>
                  {patent.applicants.length === 0 && <MissingData />}
                  {patent.applicants.map((applicant, index) => {
                    if (index < patent.applicants.length - 1) return capitalizeFirstLetter(applicant) + ' || '
                    else return capitalizeFirstLetter(applicant)
                  })}
                </td>
                <td>{patent.title ? patent.title : <MissingData />}</td>
                <td>{patent.year}</td>
                <td>
                  {patent.patentUrl ? (
                    <a href={patent.patentUrl} target="_blank">
                      {patent.patent_num}
                    </a>
                  ) : (
                    <>{patent.patent_num}</>
                  )}
                </td>
                <td>
                  {patent.num_of_patents_in_family}
                  {patent.grouped_by_title && <span>*</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No data</div>
      )}
    </div>
  )
}

export default InventionsTable
