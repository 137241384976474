import { useEffect, useState } from 'react'
import styles from './TextWordSpliterInput.module.scss'

type Props = {
  filterInputVal: string
  onFilterValueChange: Function
}

// This component before was named MultiSelectFilter, BUT is not related to a filter:
// It rather handles the splitting and manipulation of an input value into separate parts.
// The component keeps track of the individual parts and provides functionality for modifying them.
function TextWordSpliterInput({ filterInputVal, onFilterValueChange }: Props) {
  let [valueParts, setValueParts] = useState<string[]>([''])

  const inputValSplit = () => filterInputVal.split(' ')

  useEffect(() => {
    let split = filterInputVal.split(' ')
    if (split.length > 1 && split[split.length - 2].trim().length === 0) {
      split.pop()
    }

    setValueParts(split)
  }, [filterInputVal])

  const onChange = (value: string) => {
    valueParts[valueParts.length - 1] = value
    onFilterValueChange(valueParts.join(' '))
  }

  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    let key = ev.key

    switch (key) {
      case 'Enter':
      case ' ': {
        let lastValuePart = valueParts[valueParts.length - 1]

        if (lastValuePart.length === 0) {
          ev.preventDefault()
          return
        } else {
          let lastLetter = lastValuePart[lastValuePart.length - 1]

          if (lastLetter === ' ') {
            ev.preventDefault()
            return
          }
        }
        onChange(lastValuePart + ' ')
        break
      }
      case 'Backspace': {
        let lastValuePart = valueParts[valueParts.length - 1]
        if (valueParts.length > 1 && lastValuePart.length === 0) {
          ev.preventDefault()
          let newValueParts = valueParts.slice(0, -1)
          onFilterValueChange(newValueParts.join(' '))
        }
      }
    }
  }

  return (
    <div className={styles.base}>
      {valueParts.map((word, index) => {
        if (index < inputValSplit().length - 1) {
          return (
            <span key={index} className={styles.inputValsContainer}>
              <div className={styles.inputValWord}>{word}</div>

              <div className={styles.inputAND}>AND</div>
            </span>
          )
        } else {
          return (
            <input
              key={index}
              placeholder="Type to filter"
              type="text"
              onChange={ev => onChange(ev.target.value)}
              onKeyDown={ev => onKeyDown(ev)}
              value={valueParts[valueParts.length - 1]}
              autoFocus={true}
            />
          )
        }
      })}
    </div>
  )
}

export default TextWordSpliterInput
