import { useState, useRef, useEffect } from 'react'
import {
  MdExpandMore as ExpandMore,
  MdExpandLess as ExpandLess,
} from 'react-icons/md'

import styles from './ResultColumn.module.scss'

type Props = {
  customStyle?: string
  children: JSX.Element | JSX.Element[] | string | string[]
}

function ResultColumn({ children, customStyle = '' }: Props) {
  const [isOverflow, setIsOverflow] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const [openColumn, setOpenColumn] = useState(false)

  useEffect(() => {
    if (divRef.current) {
      const hasOverflow = divRef.current.scrollHeight > divRef.current.clientHeight
      setIsOverflow(hasOverflow)
    }
  }, [])

  return (
    <div
      className={`${styles.base} ${styles[customStyle]} ${
        openColumn ? styles.opened : styles.closed
      } `}
      ref={divRef}>
      {isOverflow || openColumn ? (
        <>
          <div className={styles.withOverflow}>{children}</div>
          <span onClick={() => setOpenColumn(!openColumn)}>
            {openColumn ? <ExpandLess /> : <ExpandMore />}
          </span>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export default ResultColumn
