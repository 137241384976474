import styles from './SearchOverview.module.scss'
import { SearchInfo } from 'store/dashboard/types'

type Props = {
  searchInfo: SearchInfo
}
const SearchOverview = ({ searchInfo }: Props) => {
  return (
    <div className={styles.base}>
      <h2>Search Overview</h2>
      <div className={styles.searchOverview}>
        <div className={styles.item}>
          <span className={styles.label}>Searched by: </span>
          {searchInfo.firstname} {searchInfo.lastname} ({searchInfo.email})
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Company/Project name: </span> {searchInfo.name}
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Search carried out: </span>
          {searchInfo.created_at}
        </div>

        <hr />

        <div className={styles.item}>
          <span className={styles.label}>Search id:</span> {searchInfo.search_id}
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Categories selected:</span>
          <span>{searchInfo.parent_categories}</span>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Sub-categories selected:</div>
          <div className="sub-category-container">
            <span>{searchInfo.sub_categories.map(cat => cat.name)}</span>
          </div>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Countries selected: </span>
          <span>
            {searchInfo.countries.map((country, index) => {
              if (index < searchInfo.countries.length - 1) {
                return <>{country}, </>
              } else {
                return <>{country}</>
              }
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SearchOverview
