import { configureStore } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { useDispatch } from 'react-redux'
import rootReducer from './rootReducer'
import { createInjectorsEnhancer } from 'redux-injectors'
import rootSaga from './rootSaga'

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

const reduxSagaMonitorOptions = {}
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

const { run: runSaga } = sagaMiddleware

// sagaMiddleware: Makes redux-sagas work
const middleware = [sagaMiddleware, logger]

const enhancers = [
  createInjectorsEnhancer({
    createReducer: () => rootReducer,
    runSaga,
  }),
]

// sagaMiddleware.run(rootSaga)

//createStore eq
export const store = configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState: {},
  devTools: process.env.NODE_ENV !== 'production',
  enhancers,
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store
