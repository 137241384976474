import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { actions as searchActions } from 'store/search'
import { SearchRequest } from 'store/search/types'
import { Category, Filter } from 'containers/search/types'
import { RootState } from 'store/'
import { useAppDispatch } from 'store/hooks'

import Button from 'components/button/Button'
import CardTitle from 'components/cardTitle/CardTitle'

import { ReportRequestType } from 'containers/search/types'

import styles from './ConfirmationStep.module.scss'

type Props = {
  reportName: string
  data: ReportRequestType
  filter: Filter
  setReportStep: () => void
  comeBack: () => void
  categoriesLevel1: Category[]
  categoriesLevel2: Category[]
}

const todayDate = new Date().toDateString()

function ConfirmationStep(props: Props) {
  const history = useNavigate()
  const dispatch = useAppDispatch()
  const [reportSubmitted, setReportSubmitted] = useState<boolean>(false)
  const searches = useSelector((state: RootState) => state.search.searches)
  const searchError = useSelector((state: RootState) => state.search.searchError)
  const countries = props.filter?.['countries']

  const dispatchData: SearchRequest = {
    name: props.data!.name,
    [props.data?.type || 'text']: props.data?.value,
    filter: props.filter,
    userWords: props.data!.userWords,
  }

  useEffect(() => {
    if (searches.length > 0) {
      history(`/report/${searches[0].ID}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searches])

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setReportSubmitted(true)
    dispatch(searchActions.submitSearch(dispatchData))
  }

  const truncateText = (text: string) => {
    const maxLen = 200
    if (text.length <= maxLen) return text
    return text.substr(0, text.lastIndexOf(' ', maxLen)) + '...'
  }

  const showDataToConfirm = (data: ReportRequestType) => {
    const value = data.value
    if (value) {
      return typeof value == 'string' ? truncateText(value) : value.name
    }
  }

  const getTopLevelSelectedCategoryNames = () => {
    const selectedIds: (string | number)[] = props?.filter?.classificationsIPCR || []
    const selectedCategories = props.categoriesLevel2.filter(
      cat2 => selectedIds.indexOf(cat2.id) > -1
    )
    const selectedParents = selectedCategories.map(cat => cat.parent)

    let parentListNames = props.categoriesLevel1
      .filter(cat => selectedParents.indexOf(cat.id) > -1)
      .map(cat => cat.name)
      .sort((a, b) => (a > b ? 1 : -1))

    return parentListNames
  }

  const getSelectedSubCategoryNames = () => {
    let selectedIds: (string | number)[] = props?.filter?.classificationsIPCR || []

    return props.categoriesLevel2
      .filter(cat => selectedIds.indexOf(cat.id) > -1)
      .map(cat => cat.name)
      .sort((a, b) => (a > b ? 1 : -1))
  }

  return (
    <div className={styles.base}>
      <div className={styles.card}>
        <CardTitle>Confirm and run search</CardTitle>
        <form onSubmit={handleOnSubmit}>
          <div className={styles.cardContent}>
            <div className={styles.section}>
              <b>Company/Project name:</b> {props.data.name}
            </div>
            <div className={styles.section}>
              <b>Keywords:</b> {props.data.userWords}
            </div>
            <div className={styles.section}>
              <b>Categories selected:</b>{' '}
              {getTopLevelSelectedCategoryNames()?.join(' / ')}
            </div>

            <div className={styles.section}>
              <b>Sub-categories selected:</b>
              <div className={styles.subCategoriesContainer}>
                {getSelectedSubCategoryNames()?.map(p => (
                  <div key={p} className={styles.subCategory}>
                    {p}
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.section}>
              <b>Countries:</b> {countries!.join(', ')}
            </div>

            <div className={styles.section}>
              <b>Date:</b> {todayDate}
            </div>
            {typeof dispatchData.scrapeLimitIncreased === 'boolean' && (
              <div
                className={styles.section}
                data-testid="scrape-limit-increased-text">
                <b>Increase limit of sub-pages included in search:</b>{' '}
                {dispatchData.scrapeLimitIncreased ? 'yes' : 'no'}
              </div>
            )}
            <div className={styles.section}>
              <b>Search input: </b> {showDataToConfirm(props.data)}
            </div>

            {searchError && <div className={styles.errorMessage}>{searchError}</div>}

            <div className={styles.actions}>
              <Button
                title="Back"
                type="button"
                secondary
                onClick={() => {
                  props.comeBack()
                }}
              />
              <Button
                title="Get Report"
                type="submit"
                loading={!Boolean(searchError) && reportSubmitted}
                disabled={Boolean(searchError) || reportSubmitted}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ConfirmationStep
