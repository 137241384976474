import { Result } from 'store/adminArea/types'
import ResultColumn from '../resultColumn/ResultColumn'
import styles from './ResultRow.module.scss'

type Props = {
  result: Result
}

function ResultRow({ result }: Props) {
  return (
    <div id={result.id} className={styles.base}>
      <ResultColumn customStyle="first">{result.rank.toString()}</ResultColumn>
      <ResultColumn customStyle="score">
        <span title={result.score.toString()}>{result.score.toString().slice(0, 6)}</span>
      </ResultColumn>
      <ResultColumn customStyle="score">
        <span title={result.scoreBigrams.toString()}>{result.scoreBigrams.toString().slice(0, 5)}</span>
      </ResultColumn>
      <ResultColumn customStyle="intersection">{result.reverseIntersection.toString().slice(0, 5)}</ResultColumn>
      <ResultColumn customStyle="country">{result.country}</ResultColumn>
      <ResultColumn customStyle="patentNum">{result.patentNum}</ResultColumn>
      <ResultColumn customStyle="classes">{result.classificationsIPCR.join(', ')}</ResultColumn>
      <ResultColumn>{result.applicants?.join(', ')}</ResultColumn>
      <ResultColumn customStyle="title">{result.title}</ResultColumn>
      <ResultColumn customStyle="terms">{result.wordMatches.join(', ')}</ResultColumn>
    </div>
  )
}

export default ResultRow
