import { put, takeEvery, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import * as Api from 'services/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompareResultType, TextType } from 'containers/compare/commonTypes'

export interface Search {
  ID: string
  name?: string
  full_text?: any
  wordPairs?: any
}

export interface CompareState {
  searchTexts: string[]
  searchDocuments: File[]
  results: CompareResultType[]
  fetchResultsStatus: string
  uploadProgress: number | null
}

type TextProcessorResponse = AxiosResponse<Search>

/**
 * Initial value(s) of the stores state
 */
const initialState: CompareState = {
  searchTexts: [],
  searchDocuments: [],
  results: [],
  fetchResultsStatus: '',
  uploadProgress: null,
}

const slice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    addSearchText: (state, action: PayloadAction<string>) => {
      state.searchTexts.push(action.payload)
    },
    updateSearchText: (state, action: PayloadAction<TextType>) => {
      state.searchTexts[action.payload.index] = action.payload.text
    },
    deleteSearchText: (state, action: PayloadAction<number>) => {
      state.searchTexts.splice(action.payload, 1)
    },
    resetSearchTexts: state => {
      state.searchTexts = []
    },
    preprocess: (state, action: PayloadAction<File>) => {},
    addSearchDocument: (state, action: PayloadAction<File>) => {
      state.searchDocuments.push(action.payload)
    },
    deleteSearchDocument: (state, action: PayloadAction<number>) => {
      state.searchDocuments.splice(action.payload, 1)
    },
    resetSearchDocuments: state => {
      state.searchDocuments = []
    },
    setResults: (state, action: PayloadAction<CompareResultType[]>) => {
      state.results = action.payload
    },
    setFetchResultsStatus: (state, action: PayloadAction<string>) => {
      state.fetchResultsStatus = action.payload
    },
    setUploadProgress: (state, action: PayloadAction<number | null>) => {
      state.uploadProgress = action.payload
    },
  },
})

export const actions = slice.actions
export const reducer = slice.reducer

/**
 * Saga watcher
 */
export function* sagas() {
  yield takeEvery(actions.preprocess, preprocessSaga)
}

/**
 * Sagas
 */
export const preprocessSaga = function* (action: any) {
  const file = action.payload
  const response: TextProcessorResponse = yield call(
    Api.postForm,
    Api.ENDPOINTS.preprocess,
    {
      file,
    }
  )
  yield put(actions.addSearchText(response.data.full_text))
}
