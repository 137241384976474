import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions, UserSearch } from 'store/adminArea'
import { BiExport as ExportIcon } from 'react-icons/bi'
import ResultRow from './components/resultRow/ResultRow'
import ResultColumn from './components/resultColumn/ResultColumn'
import styles from './ResultTable.module.scss'
import { Search, Result } from 'store/adminArea/types'
import Spinner from 'components/spinner/Spinner'

type Props = {
  userId: string
  search: Search
  results: Result[]
}
const ResultTable = ({ userId, search, results }: Props) => {
  const searchId = search.ID
  const reportId = search.report_id
  const error = useSelector((state: RootState) => state.adminArea.error.results)
  const resultsLoading = useSelector((state: RootState) => state.adminArea.loading.results)
  const exportLoading = useSelector((state: RootState) => state.adminArea.loading.export)

  const exportForAnalysisLoading = useSelector((state: RootState) => state.adminArea.loading.exportForAnalysis)
  const dispatch = useAppDispatch()
  const exportResults = () => {
    if (!exportLoading) {
      const data: UserSearch = { userId, searchId }
      dispatch(dashboardActions.exportResults(data))
    }
  }

  const exportResultsForAnalysis = () => {
    if (!exportForAnalysisLoading) {
      const data: UserSearch = { userId, searchId }
      dispatch(dashboardActions.exportResultsForAnalysis(data))
    }
  }

  const openLinkToReportData = () => {
    const url = `https://s3.console.aws.amazon.com/s3/buckets/${process.env.REACT_APP_REPORT_BUCKET}?prefix=${reportId}/&region=eu-west-2`

    window.open(url, '_blank', 'noreferrer')
  }

  return (
    <div className={styles.base}>
      <div className={styles.exportResultsContainer}>
        <div className={`${styles.exportResults} ${exportLoading ? styles.exportLoading : ''}`} onClick={() => exportResults()}>
          <ExportIcon size={'1.3em'} /> <div> Export top 250 results</div>
        </div>
        <div
          className={`${styles.exportResults} ${exportForAnalysisLoading ? styles.exportLoading : ''}`}
          onClick={() => exportResultsForAnalysis()}>
          <ExportIcon size={'1.3em'} /> <div> Export for analysis</div>
        </div>
        <div
          className={`${styles.exportResults} ${exportForAnalysisLoading ? styles.exportLoading : ''}`}
          onClick={() => openLinkToReportData()}>
          <ExportIcon size={'1.3em'} /> <div> Link to report data</div>
        </div>
      </div>
      <div className={styles.headerRow}>
        <ResultColumn customStyle="first"> # </ResultColumn>
        <ResultColumn customStyle="score">Score</ResultColumn>
        <ResultColumn customStyle="score">Score bigrams</ResultColumn>
        <ResultColumn customStyle="intersection">Reverse intersec.</ResultColumn>
        <ResultColumn customStyle="country">Country</ResultColumn>
        <ResultColumn customStyle="patentNum">Patent number</ResultColumn>
        <ResultColumn customStyle="classes">Classes</ResultColumn>
        <ResultColumn>Applicants</ResultColumn>
        <ResultColumn customStyle="title">Title</ResultColumn>
        <ResultColumn customStyle="terms">Matched terms</ResultColumn>
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {resultsLoading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
      {results?.slice(0, 50).map((result, index) => (
        <ResultRow result={result} key={index} />
      ))}
      {results?.length === 0 && <div>No results</div>}
    </div>
  )
}

export default ResultTable
