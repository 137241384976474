import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useAppDispatch } from 'store/hooks'
import { actions as dashboardActions, UserSearch } from 'store/adminArea'
import { actions as searchActions } from 'store/search'
import { MdExpandMore as ExpandMore } from 'react-icons/md'
import { MdExpandLess as ExpandLess } from 'react-icons/md'
import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md'

import { Search } from 'store/adminArea/types'
import SearchColumn from 'containers/adminArea/containers/userDetails/components/searchTable/components/searchColumn/SearchColumn'
import ResultTable from 'containers/adminArea/containers/userDetails/components/resultTable/ResultTable'
import styles from './SearchRow.module.scss'
import { Link } from 'react-router-dom'

type Props = {
  userId: string
  search: Search
}

function SearchRow({ userId, search }: Props) {
  const downloadReportLoading = useSelector((state: RootState) => state.search.loading.downloadReport)

  const generateReportError = useSelector((state: RootState) => state.adminArea.error.generateReport) === search.ID
  const generateReportLoading = useSelector((state: RootState) => state.adminArea.loading.generateReport) === search.ID
  const dispatch = useAppDispatch()
  const [showResuts, setShowResuts] = useState(false)
  const [clipboard, setClipboard] = useState(false)

  const getDate = (timestamp: string) => {
    if (timestamp) {
      const d = new Date(timestamp)
      const date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} `
      const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
      const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()
      const time = `${d.getHours()}:${minutes}:${seconds} `
      return date + time
    } else return ''
  }
  const getResults = () => {
    setShowResuts(!showResuts)
    const data: UserSearch = { userId: userId, searchId: search.ID }
    dispatch(dashboardActions.getUserSearchResults(data))
  }

  const downloadReport = (search: Search) => {
    if (!downloadReportLoading) dispatch(searchActions.downloadReport(search))
  }

  const generateReport = (search: Search) => {
    const data = {
      userId: userId,
      id: search.ID,
    }
    if (!downloadReportLoading) dispatch(dashboardActions.generateUserReport(data))
  }

  return (
    <div id={search.ID} className={styles.base}>
      <div className={styles.rowContainer}>
        <div className={styles.row}>
          <SearchColumn customStyle="first">
            <span>
              {showResuts ? (
                <ExpandLess size="1.7em" onClick={() => setShowResuts(false)} />
              ) : (
                <ExpandMore size="1.7em" onClick={() => getResults()} />
              )}
            </span>
          </SearchColumn>
          <SearchColumn customStyle="id">
            <div title="Copy search id to clipboard" className={styles.clipboard}>
              <CopyToClipboard
                text={search.ID}
                onCopy={() => {
                  setClipboard(true)
                  setTimeout(() => {
                    setClipboard(false)
                  }, 2000)
                }}>
                <CopyIcon size="1.1rem" />
              </CopyToClipboard>
              {clipboard && <div>Copied!</div>}
            </div>
          </SearchColumn>
          <SearchColumn customStyle="createdAt">{getDate(search.created_at)}</SearchColumn>
          <SearchColumn customStyle="status">
            {search.status} / {search.report_status ? search.report_status : '-'}
          </SearchColumn>
          <SearchColumn customStyle="name">{search.name}</SearchColumn>
          <SearchColumn customStyle="text">{search.text}</SearchColumn>
          <SearchColumn customStyle="territories">{search.filter?.classificationsIPCR?.join(', ')}</SearchColumn>
          <SearchColumn customStyle="territories">{search.filter?.countries?.join(', ')}</SearchColumn>
          <SearchColumn customStyle="terms">{search.terms.join(', ')}</SearchColumn>
          <SearchColumn customStyle="report">
            <div className={styles.report}>
              {search.report_status === 'completed' ? (
                <button
                  className={`${styles.link} ${downloadReportLoading ? styles.loading : ''}`}
                  onClick={() => downloadReport(search)}
                  disabled={downloadReportLoading}>
                  Download
                </button>
              ) : (
                '-'
              )}

              {['search_finished', 'complete'].includes(search.status) && (
                <>
                  <button
                    className={`${styles.link} ${styles.regenerate} ${generateReportLoading ? styles.loading : ''}`}
                    onClick={() => generateReport(search)}
                    disabled={generateReportLoading}>
                    {search.report_status ? 'Regenerate' : 'Generate'}
                  </button>
                  <Link className={styles.link} target="_blank" to={`/dashboard/reporting/report/${search.ID}`}>
                    Web report
                  </Link>
                </>
              )}
            </div>
          </SearchColumn>
        </div>
        {generateReportError && <div className={styles.error}>Generate Report Error</div>}
      </div>
      {showResuts && <ResultTable userId={userId} search={search} results={search?.results} />}
    </div>
  )
}

export default SearchRow
