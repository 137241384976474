import React from 'react'
import styles from './Navigation.module.scss'

type NavigationProps = {
  title: string
  left?: boolean
  right?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
}

function Navigation({title, left, right, onClick, disabled = false}: NavigationProps) {
  return (
    <button className={styles.base} type='button' onClick={onClick} disabled={disabled}>
      {left && '< '}
      {title}
      {right && ' >'}
    </button>
  )
}

export default Navigation
