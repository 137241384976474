import { useEffect } from 'react'
import { RootState } from 'store/'
import { useAppDispatch } from 'store/hooks'
import { actions as searchActions } from 'store/search'
import { ReportDataRequest, actions as dashboardActions } from 'store/dashboard'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import useInterval from 'use-interval'
import Spinner from 'components/spinner/Spinner'
import Button from 'components/button/Button'
import Report from './report/Report'
import styles from './ReportingPage.module.scss'

type ParamsType = {
  id: string
}

const ReportingPage = () => {
  const dispatch = useAppDispatch()
  const searchStatus = useSelector((state: RootState) => state.search.searchStatus)
  const searchStatusLoading = useSelector((state: RootState) => state.search.loading.searchStatus)
  const errorSearch = useSelector((state: RootState) => state.search.error)
  const reportData = useSelector((state: RootState) => state.dashboard.reportData)
  const reportDataLoading = useSelector((state: RootState) => state.dashboard.loading.reportData)
  const errorDashboard = useSelector((state: RootState) => state.dashboard.error)
  const downloadPdfLoading = useSelector((state: RootState) => state.dashboard.loading.downloadPdf)
  const params = useParams<ParamsType>()
  const searchId = params?.id || ''
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const limit = queryParams.has('limit') ? parseInt(queryParams.get('limit')!) : null

  useEffect(() => {
    console.log('useEffect 2 - getSearchStatus')
    dispatch(searchActions.getSearchStatus(searchId))
  }, [dispatch, searchId])

  const getReportData = () => {
    const data: ReportDataRequest = {
      id: searchId,
      ...(limit && { limit: limit }),
    }
    dispatch(dashboardActions.fetchReportData(data))
  }

  const regenerateReportData = () => {
    const data: ReportDataRequest = {
      id: searchId,
      regenerate: true,
      ...(limit && { limit: limit }),
    }
    dispatch(dashboardActions.fetchReportData(data))
  }

  useEffect(() => {
    if (searchStatus) {
      console.log('useEffect - getReportData')
      getReportData()
    }
  }, [dispatch, searchStatus])

  useInterval(
    () => {
      console.log('useInterval - getReportData')
      dispatch(searchActions.getSearchStatus(searchId))
    },
    searchStatus === 'report_compiling' ? 6000 : null
  )

  const isLoading = () => {
    if (reportDataLoading || searchStatusLoading || searchStatus === 'report_compiling') return true
  }

  const downloadPdf = () => {
    const data = {
      searchId,
      pdfFileName: reportData!.search_info.name + '_' + reportData!.search_info.created_at,
    }
    dispatch(dashboardActions.downloadPdf(data))
  }

  return (
    <div className={styles.base}>
      <h1 className={styles.title}>INVESTOR IP INSIGHTS REPORT</h1>
      {['queued', 'search_processing'].includes(searchStatus!) && <div>Search is not ready. Status: {searchStatus} </div>}
      {searchStatus === 'report_compiling' && <div>Report is being compiled. </div>}
      {errorSearch.searchStatus && <div className={styles.error}>{errorSearch.searchStatus}</div>}

      {isLoading() && (
        <div className={styles.loading}>
          <Spinner size={50} />
        </div>
      )}
      {errorDashboard.reportData && <div className={styles.error}>{errorDashboard.reportData}</div>}

      {limit && <div>Limit: {limit}</div>}

      {reportData && (
        <>
          <div className={styles.searchInfo}>Company/Project name: {reportData.search_info.name}</div>
          <div className={styles.buttons}>
            <div className={styles.btnContainer}>
              <Button title="Get PDF" type="submit" onClick={downloadPdf} loading={downloadPdfLoading} />
              {errorDashboard.downloadPdf && <div className={styles.error}>{errorDashboard.downloadPdf}</div>}
            </div>
            <div className={styles.btnContainer}>
              <Button title="Regenerate report data" type="submit" onClick={regenerateReportData} loading={downloadPdfLoading} />
              {errorDashboard.downloadPdf && <div className={styles.error}>{errorDashboard.downloadPdf}</div>}
            </div>
          </div>
          <Report reportData={reportData} />
        </>
      )}
    </div>
  )
}

export default ReportingPage
