import React, {useRef} from 'react'
import {useEffect} from 'react'
import Button from 'components/button/Button'
import styles from './FileSearch.module.scss'

type FileSearchTypeProps = {
  file: File | undefined
  setFile: (data: File | undefined) => void
}
function FileSearch({file, setFile}: FileSearchTypeProps) {
  let inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (file) setFile(file as File)
  }, [file, setFile])

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFile(e.currentTarget.files?.[0])
    if (inputRef.current) inputRef.current.files = null
  }

  const handleFileChoice = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    inputRef.current && inputRef.current.click()
  }

  const handleClearForm = () => {
    setFile(undefined)
    if (inputRef.current) inputRef.current.files = null
  }

  return (
    <div className={styles.base}>
      <form>
        <Button
          title='Select file'
          type='button'
          secondary={true}
          onClick={handleFileChoice}
          disabled={Boolean(file)}
        />
        <input
          data-testid='search-setup-file-search'
          style={{display: 'none'}}
          type='file'
          onChange={handleFileSelect}
          ref={inputRef}
          onDrop={() => {}}
        />
        {file && (
          <div className={styles.filenameContainer}>
            <div className={styles.filename}>{file.name}</div>
            <div className={styles.deleteFile} onClick={handleClearForm}>
              x
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default FileSearch
