import { useState } from 'react'
import { postForm, ENDPOINTS } from 'services/api'
import { useParams, useNavigate } from 'react-router-dom'
import ReCaptcha from 'components/reCaptcha/ReCaptcha'
import CardTitle from 'components/cardTitle/CardTitle'
import FormInput from 'components/formInput/FormInput'
import Button from 'components/button/Button'
import PasswordRequirements from 'components/passwordRequirements/PasswordRequirements'
import validatePassword from 'containers/user/utils'
import styles from './ResetPassword.module.scss'

type ParamsType = {
  token: string
}

function ResetPassword(): JSX.Element {
  const history = useNavigate()
  const { token } = useParams<ParamsType>()
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [inputError, setInputError] = useState<null | string>(null)
  const [error, setError] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(false)
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const handleInputChange = (event: any) => {
    setSubmitDisabled(false)
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
    setInputError(null)
    setError('')
  }

  const handleSubmit = () => {
    const isValidPassword = validatePassword(data.newPassword)
    if (!isValidPassword) {
      setInputError('Password must meet the following requirements')
    } else if (data.newPassword === data.confirmPassword) {
      setSubmitDisabled(true)
      const resp = postForm(ENDPOINTS.resetPassword, {
        token: token,
        newPassword: data.newPassword,
      })
      resp.then(r => {
        console.log(r)
        setPasswordUpdated(true)
        history(`/login`)
      })
      resp.catch(error => {
        setSubmitDisabled(false)
        if (error?.response?.status === 401) {
          setError('The reset password link has expired. Please, request a new one.')
        } else {
          setError('An error occurred. Please try again.')
        }

        console.log(error)
      })
    } else {
      setError("Passwords don't match")
    }
  }

  return (
    <div className={styles.base}>
      {passwordUpdated && (
        <div className={styles.card}>
          <h3>Password updated</h3>
        </div>
      )}
      {!passwordUpdated && (
        <div className={styles.card}>
          <CardTitle>Reset your password.</CardTitle>
          <div className={styles.formContainer}>
            <form className={styles.form}>
              <FormInput
                onChange={handleInputChange}
                name="newPassword"
                label="New password"
                value={data.newPassword}
                type="password"
                error={inputError}
              />
              <PasswordRequirements password={data.newPassword} />
              <FormInput
                onChange={handleInputChange}
                name="confirmPassword"
                label="Confirm new password"
                value={data.confirmPassword}
                type="password"
              />

              {error && (
                <div className={styles.error} data-testid="reset-password-error">
                  {error}
                </div>
              )}
              <div className={styles.reCaptcha}>
                <ReCaptcha
                  onChange={() => {
                    setCaptchaVerified(true)
                  }}
                />
              </div>
              <div className={styles.btnContainer}>
                <Button
                  title="Save password"
                  type="button"
                  onClick={handleSubmit}
                  data-testid="submit"
                  disabled={
                    !data.newPassword ||
                    !data.confirmPassword ||
                    submitDisabled ||
                    !captchaVerified
                  }
                  loading={submitDisabled}
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <br />
    </div>
  )
}

export default ResetPassword
