import {useEffect} from 'react'
import {useAppDispatch} from 'store/hooks'
import {useSelector} from 'react-redux'
import {RootState} from 'store'
import {actions as compareActions} from 'store/compare'
import {ENDPOINTS} from 'services/api'
import {authPostForm} from 'services/api'
import {ReactComponent as TrashIcon} from 'assets/images/trash.svg'
import Button from 'components/button/Button'
import CardTitle from 'components/cardTitle/CardTitle'
import styles from './SearchDocumentsStep.module.scss'
import {StepTypeProps} from 'containers/compare/commonTypes'

function SearchDocumentsStep({setNextStep}: StepTypeProps) {
  const dispatch = useAppDispatch()
  const searchDocuments = useSelector((state: RootState) => state.compare.searchDocuments)
  const uploadProgress = useSelector((state: RootState) => state.compare.uploadProgress)
  const searchTexts = useSelector((state: RootState) => state.compare.searchTexts)

  useEffect(() => {
    if (uploadProgress === 100) {
      dispatch(compareActions.setUploadProgress(null))
      setNextStep('results')
    }
  }, [uploadProgress])

  const ProgressBar = (props: any) => {
    const {completed} = props
    return (
      <div className={styles.progressBarContainer}>
        Uploading:
        <div className={styles.progressBar}>
          <div style={{width: `${completed}%`}}>
            <span>{`${completed}%`}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleFileSelect = (e: any) => {
    e.preventDefault()
    const uploadedFiles = [...e.target.files]
    if (uploadedFiles.length)
      uploadedFiles.forEach((file) => {
        dispatch(compareActions.addSearchDocument(file))
      })
  }

  const removeSearchDocument = (index: number) => dispatch(compareActions.deleteSearchDocument(index))

  const submit = () => {
    const data = {
      text: searchTexts,
      files: searchDocuments,
      ngramSize: 3,
    }

    try {
      dispatch(compareActions.setResults([]))
      const resp = authPostForm(ENDPOINTS.compare, data)
      dispatch(compareActions.setFetchResultsStatus('loading'))
      resp
        .then((r) => {
          dispatch(compareActions.setResults(r.data))
          dispatch(compareActions.setFetchResultsStatus(''))
        })
        .catch((error) => {
          console.log('SearchDocumentStep - error - ' + error)
          dispatch(compareActions.setFetchResultsStatus('error'))
        })
    } catch (error) {
      console.log(error)
      dispatch(compareActions.setFetchResultsStatus('error'))
    }
  }

  return (
    <div className={styles.base}>
      <CardTitle>Select documents to search in</CardTitle>
      <div id='fileSearch' className={`${styles.uploadWrapper}`}>
        <label>
          <Button title='Choose file(s)' type='button' />
          <div>or drag file(s) here</div>
          <input id='file-input' className={styles.uploadInput} onChange={handleFileSelect} multiple type='file' />
        </label>
      </div>
      {searchDocuments.length > 0 && (
        <div className={styles.selectedDocumentsText}>{searchDocuments.length} document(s) selected</div>
      )}
      <div className={styles.documentsList}>
        {searchDocuments.map((file, index) => (
          <div key={index} className={`${styles.documentRow} ${index % 2 === 0 ? styles.odd : ''}`}>
            <div className={styles.documentFilename}>{file.name.split('.').slice(0, -1).join('.')}</div>
            <div className={styles.documentExtension}>{file.name.split('.').pop()}</div>
            <div onClick={() => removeSearchDocument(index)}>
              <TrashIcon className={styles.trashIcon} data-testid='trash' />
            </div>
          </div>
        ))}
      </div>

      {uploadProgress && (
        <div>
          <ProgressBar key={uploadProgress} bgcolor={'red'} completed={uploadProgress} />
        </div>
      )}
      <div className={styles.backNextBtns}>
        <Button title='Back' type='button' secondary onClick={() => setNextStep('searchText')} />
        <Button title='Search' type='button' onClick={submit} disabled={Boolean(searchDocuments.length === 0)} />
      </div>
    </div>
  )
}

export default SearchDocumentsStep
