import styles from './CardTitle.module.scss'

type Props = {
  small?: Boolean
  children: JSX.Element | JSX.Element[] | string | string[]
}
function CardTitle({children, small = false}: Props) {
  return (
    <div className={`${styles.base} ${small ? styles.small : ''}`}>
      <div>{children}</div>
      <div className={styles.separator}></div>
    </div>
  )
}

export default CardTitle
