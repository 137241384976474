import React, {useEffect, useState} from 'react'
import {useAppDispatch} from 'store/hooks'
import {useSelector} from 'react-redux'
import {RootState} from 'store'
import {actions as compareActions} from 'store/compare'
import {ReactComponent as TrashIcon} from 'assets/images/trash.svg'
import {ReactComponent as PlusIcon} from 'assets/images/plus.svg'
import Button from 'components/button/Button'
import CardTitle from 'components/cardTitle/CardTitle'
import styles from './SearchTextStep.module.scss'
import {StepTypeProps, TextType} from 'containers/compare/commonTypes'

function SearchTextStep({setNextStep}: StepTypeProps) {
  const [error, setError] = useState('')
  const dispatch = useAppDispatch()
  const searchTexts = useSelector((state: RootState) => state.compare.searchTexts)

  const updateSearchText = (searchText: TextType) => {
    setError('')
    dispatch(compareActions.updateSearchText(searchText))
  }

  const addTextArea = (e: any) => {
    setError('')
    dispatch(compareActions.addSearchText(''))
  }

  const removeSearchText = (index: number) => dispatch(compareActions.deleteSearchText(index))

  const handleNextBtnClick = (e: React.FormEvent) => {
    searchTexts.map((text) => {
      if (text !== '') setNextStep('searchDocuments')
    })
    setError('You must enter at least one field.')
  }

  useEffect(() => {
    dispatch(compareActions.setUploadProgress(null))
    if (searchTexts.length === 0) dispatch(compareActions.addSearchText(''))
  })

  return (
    <div className={styles.base}>
      <CardTitle>Start Contract Comparison Search</CardTitle>
      {searchTexts.map((t, index) => (
        <div className={styles.textareaContainer} key={index}>
          <textarea
            data-testid='search-setup-text-input'
            value={searchTexts[index]}
            onChange={(ev) => updateSearchText({index, text: ev.target.value})}
            rows={5}
            cols={110}
          />
          {searchTexts.length > 1 && (
            <div className={styles.trashIcon} onClick={() => removeSearchText(index)}>
              <TrashIcon data-testid='trash' />
            </div>
          )}
        </div>
      ))}
      {error && <div className={styles.error}>{error}</div>}
      {searchTexts.length < 5 && (
        <div className={styles.uploadWrapper} onClick={addTextArea}>
          <PlusIcon data-testid='trash' />
          {searchTexts.length > 0 && <div>Additional text field (max 5)</div>}
        </div>
      )}
      {searchTexts.length !== 0 && (
        <div className={styles.nextBtn}>
          <Button title='Next step' type='button' onClick={handleNextBtnClick} />
        </div>
      )}
    </div>
  )
}

export default SearchTextStep
