import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useInterval from 'use-interval'

import { useAppDispatch } from 'store/hooks'
import { actions as searchActions, selectors } from 'store/search'
import { RootState } from 'store/'
import { ReportRequest } from 'store/search/types'
import { ImSpinner9 as Spinner } from 'react-icons/im'
import Button from 'components/button/Button'

import styles from './ReportDownloader.module.scss'

type Props = {
  id: string
}

function ReportDownloader(props: Props) {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const reportStatus = useSelector((state: RootState) => state.search.reportStatus)
  const searchStatus = useSelector((state: RootState) => state.search.searchStatus)
  const searches = useSelector((state: RootState) => state.search.searches)
  const error = useSelector(selectors.generalError)
  const downloadReportLoading = useSelector((state: RootState) => state.search.loading.downloadReport)
  const [start, setStart] = useState(false)
  const searchId = props.id!

  useInterval(
    () => {
      dispatch(searchActions.generateReport(searchId))
    },
    start ? 30000 : null
  )

  useEffect(() => {
    if (start) {
      dispatch(searchActions.generateReport(searchId))
    }
  }, [start, dispatch])

  useEffect(() => {
    dispatch(searchActions.clearGeneralError())
    if (searches.length === 0 && searchId) {
      dispatch(searchActions.fetchSearches(searchId))
    }
  }, [dispatch, searchId, searches])

  useEffect(() => {
    if (searches.length > 0) setStart(true)
  }, [searches])

  useEffect(() => {
    console.log('searchStatus: ' + searchStatus)
  }, [searchStatus])

  useEffect(() => {
    console.log('reportStatus: ' + reportStatus)
    if (reportStatus && ['error', 'completed', 'no-results'].includes(reportStatus)) {
      setStart(false)
    }

    if (reportStatus === 'completed') {
      dispatch(searchActions.downloadReport(searches[0]))
    }
  }, [reportStatus, dispatch])

  const ProgressBar = () => {
    let num = 0

    if (reportStatus === 'completed') {
      if (downloadReportLoading) num = 90
      else num = 100
    } else if (reportStatus === 'in_progress') {
      num = 80
    } else if (reportStatus === 'started') {
      num = 40
    } else if (searchStatus === 'search_finished') {
      num = 20
    }

    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div className={styles.filler} style={{ width: `${num}%` }}></div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        <div className={styles.downloadCard}>
          <>
            {error || reportStatus === 'error' ? (
              <div className={styles.error}>{error}</div>
            ) : (
              <>
                {reportStatus !== 'completed' && reportStatus !== 'no-results' && (
                  <>
                    {reportStatus === 'in_progress' ||
                      (reportStatus === 'started' && (
                        <>
                          <h2>Report Being Compiled</h2>
                        </>
                      ))}
                    <Spinner className={styles.spinner} />
                  </>
                )}
                {reportStatus === 'completed' && (
                  <>
                    <>
                      {downloadReportLoading ? (
                        <>
                          <h2>Downloading report</h2>
                          <Spinner className={styles.spinner} />
                        </>
                      ) : (
                        <h2>Report downloaded</h2>
                      )}
                    </>
                  </>
                )}
                <ProgressBar />
              </>
            )}

            {reportStatus === 'no-results' && (
              <>
                <h2>No results from search</h2>
                <div>Unable to generate a report</div>
                <div className={styles.buttonContainer}>
                  <Button title="Search Again" type="button" onClick={() => history(`/`)} />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default ReportDownloader
