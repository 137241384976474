import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { actions as searchActions } from 'store/search'

import { get, ENDPOINTS } from 'services/api'

import SearchInputStep from './containers/searchInputStep/SearchInputStep'
import ConfirmationStep from './containers/confirmationStep/ConfirmationStep'
import { ReportRequestType, Category } from 'containers/search/types'

import styles from './ReportRequestPage.module.scss'

//const nameGen = 'generated_report_file_' + Date.now()

function ReportRequestPage() {
  const history = useNavigate()
  const name = 'generated_report_file_' + Date.now()
  const [data, setData] = useState<ReportRequestType>()
  const [searchFilter, setSearchFilter] = useState<object>({})
  const dispatch = useAppDispatch()
  const [step, setStep] = useState<string | undefined>()
  const [categoriesLevel1, setCategoriesLevel1] = useState<Category[]>([])
  const [categoriesLevel2, setCategoriesLevel2] = useState<Category[]>([])

  useEffect(() => {
    dispatch(searchActions.clearStatus())
    fetchCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (step === 'confirmation') history(`/report/confirmation`, { replace: true })
    else history(`/report`, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const fetchCategories = () => {
    // Checks to see if categories are already fetched and if not then gets them
    if (categoriesLevel1.length === 0 || categoriesLevel2.length === 0) {
      const resp1 = get(ENDPOINTS.categories, { level: 1 })
      resp1.then(r => setCategoriesLevel1(r.data)).catch(error => console.log(error))

      const resp2 = get(ENDPOINTS.categories, { level: 2 })
      resp2.then(r => setCategoriesLevel2(r.data)).catch(error => console.log(error))
    }
  }

  return (
    <div className={styles.base}>
      {!step && (
        <SearchInputStep
          goToConfirmationStep={() => setStep('confirmation')}
          setData={(data?) => setData(data)}
          setSearchFilter={(filter?) => setSearchFilter(filter!)}
          filter={searchFilter}
          categoriesLevel1={categoriesLevel1}
          categoriesLevel2={categoriesLevel2}
          onCategorySelectOpen={fetchCategories}
        />
      )}
      {step === 'confirmation' && (
        <ConfirmationStep
          reportName={name}
          data={data!}
          filter={searchFilter}
          setReportStep={() => setStep('report')}
          comeBack={() => setStep(undefined)}
          categoriesLevel1={categoriesLevel1}
          categoriesLevel2={categoriesLevel2}
        />
      )}
    </div>
  )
}

export default ReportRequestPage
