import CardTitle from 'components/cardTitle/CardTitle'
import {ReactComponent as Lock} from 'assets/images/lock.svg'
import styles from './AccountCreated.module.scss'

type Props = {
  email: string
}
function AccountCreated({email}: Props) {
  return (
    <div className={styles.base}>
      <CardTitle data-testid='loginForm'>Your account has been created</CardTitle>
      <div className={styles.image}>
        <Lock />
      </div>
      <>
        <h1>Thank you!</h1>
        <div className={styles.text}>
          <p>We have sent a confirmation link to {email}</p>
          <p>Check your inbox to verify your account.</p>
        </div>
      </>
    </div>
  )
}

export default AccountCreated
